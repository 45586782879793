import React, { ReactElement, HTMLAttributes } from "react";
import * as palette from "@govlaunch/palette";

export default function CountBadge({
  children,
  ...props
}: { children: ReactElement | number | string } & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      css={{
        display: "inline-flex",
        alignItems: "center",
        fontFamily: "monospace",
        justifyContent: "center",
        minWidth: 26,
        width: 26,
        maxWidth: 26,
        minHeight: 26,
        maxHeight: 26,
        height: 26,
        marginLeft: 6,
        padding: 2,
        color: palette.innovatorBlue,
        fontSize: 11,
        background: palette.lightestBlue,
        borderRadius: "100%",
      }}
      {...props}
    >
      {children}
    </div>
  );
}
