import React, { SVGProps } from "react";

const ProjectIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={62} height={62} viewBox="0 0 62 62" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle
        cx={29.949}
        cy={29.949}
        r={29.949}
        fill="#DAEAF6"
        stroke="#005AEE"
        strokeWidth={0.713}
        transform="translate(1 1)"
      />
      <path
        fill="#48A0D9"
        d="M17.027 23.286h27.946c.328 0 .598.258.598.571v.572H16.43v-.572c0-.313.264-.571.598-.571z"
      />
      <path fill="#E4EFF7" d="M18.143 23.286l12.31-7.754a1.526 1.526 0 011.665 0l12.31 7.754H18.144z" />
      <path
        fill="#BFD9EB"
        d="M23.022 22.143h15.956a.304.304 0 00.17-.559l-7.813-5.054a.602.602 0 00-.67 0l-7.813 5.054a.301.301 0 00-.124.345.3.3 0 00.294.214z"
      />
      <path
        fill="#E4EFF7"
        d="M17.571 24.429H44.43v2.35c0 .59-.457 1.078-1.023 1.078H18.588c-.56 0-1.017-.488-1.017-1.078v-2.35zm1.143 3.428h4v.781c0 .2-.17.362-.383.362h-3.24a.37.37 0 01-.377-.362v-.78z"
      />
      <path fill="#E4EFF7" d="M19.286 29h2.857v13.714h-2.857zm10.285 0h2.857v13.714h-2.857z" />
      <path fill="#92BFE0" d="M21.571 29h1v13.714h-1z" />
      <path fill="#EFF4F7" d="M19.286 29h1.143v13.714h-1.143z" />
      <path fill="#E4EFF7" d="M23.857 27.857h4v.781c0 .2-.17.362-.377.362h-3.24a.372.372 0 01-.383-.362v-.78z" />
      <path
        fill="#E4EFF7"
        d="M24.428 29h2.857v13.714h-2.857zm9.715-1.143h4v.781c0 .2-.175.362-.383.362h-3.235a.372.372 0 01-.382-.362v-.78z"
      />
      <path
        fill="#E4EFF7"
        d="M34.714 29h2.857v13.714h-2.857zm4.572-1.143h4v.781c0 .2-.17.362-.378.362h-3.245a.37.37 0 01-.377-.362v-.78z"
      />
      <path
        fill="#E4EFF7"
        d="M39.857 29h2.857v13.714h-2.857zm-16 15.429h4v-.782a.37.37 0 00-.377-.361h-3.24a.372.372 0 00-.383.361v.782z"
      />
      <path fill="#E4EFF7" d="M23.857 44.429h4v-.782a.37.37 0 00-.377-.361h-3.24a.372.372 0 00-.383.361v.782z" />
      <path
        fill="#E4EFF7"
        d="M23.857 44.429h4v-.782a.37.37 0 00-.377-.361h-3.24a.372.372 0 00-.383.361v.782zm10.286 0h4v-.782c0-.199-.175-.361-.383-.361h-3.235a.372.372 0 00-.382.361v.782zm5.143 0h4v-.782a.37.37 0 00-.378-.361h-3.245a.37.37 0 00-.377.361v.782z"
      />
      <path fill="#45A1DA" d="M18.1 43.857h26.37c.288 0 .53.273.53.598v1.116H17.571v-1.116c0-.325.236-.598.53-.598z" />
      <path
        fill="#BFD9EB"
        d="M16.391 45.571h29.224c.292 0 .528.267.528.598v1.117H15.857v-1.117c0-.331.241-.598.534-.598z"
      />
      <path
        fill="#92BFE0"
        d="M43.737 27.21h-25.48c-.263 0-.503-.205-.686-.496v.06c0 .593.457 1.083 1.017 1.083h24.818c.566 0 1.023-.49 1.023-1.082v-.06c-.183.29-.429.495-.692.495z"
      />
      <path fill="#BFD9EB" d="M44.429 25.571H17.57V25h26.86z" />
      <path fill="#92BFE0" d="M26.623 29h1v13.636h-1z" />
      <path fill="#EFF4F7" d="M24.429 29h1v13.638h-1z" />
      <path
        fill="#E4EFF7"
        d="M29 28.429h4v.78c0 .2-.17.362-.378.362h-3.239A.372.372 0 0129 29.21v-.781zM18.714 43.857h4v-.781a.37.37 0 00-.377-.362h-3.24a.372.372 0 00-.383.362v.781zm10.286 0h4v-.781a.37.37 0 00-.378-.362h-3.239a.372.372 0 00-.383.362v.781z"
      />
      <path fill="#92BFE0" d="M36.908 29h1v13.636h-1z" />
      <path fill="#EFF4F7" d="M34.714 29h1v13.638h-1z" />
      <path fill="#92BFE0" d="M42.051 29h1v13.636h-1z" />
      <path fill="#EFF4F7" d="M39.857 29h1v13.638h-1z" />
      <path
        fill="#48A0D8"
        d="M34.38 44.429H18.102c-.294 0-.53.215-.53.471v.671c0-.26.236-.476.53-.476h26.37c.287 0 .529.215.529.476v-.67c0-.257-.242-.472-.53-.472H34.382z"
      />
      <path
        fill="#EFF4F7"
        d="M44.69 45.571H16.391c-.293 0-.534.211-.534.474v.67c0-.258.241-.474.534-.474h29.224c.292 0 .528.216.528.473v-.669c0-.263-.236-.474-.528-.474h-.925z"
      />
      <path
        fill="#396182"
        fillRule="nonzero"
        d="M17.379 23.286h27.813a.99.99 0 01.67.248c.172.156.28.373.28.606V25H16.43v-.86a.81.81 0 01.28-.6v-.006a.99.99 0 01.67-.248zm27.813.637H17.38a.237.237 0 00-.172.062h-.006a.222.222 0 00-.068.155v.223H45.44v-.223a.215.215 0 00-.075-.155.261.261 0 00-.172-.062z"
      />
      <path
        fill="#396182"
        fillRule="nonzero"
        d="M18.116 23.802a.358.358 0 01-.49-.104.36.36 0 01.104-.492l12.515-8.174a1.88 1.88 0 011.042-.318c.356 0 .713.104 1.041.318l12.51 8.174a.35.35 0 01.103.492.35.35 0 01-.484.104l-12.514-8.18a1.191 1.191 0 00-1.312 0l-12.515 8.18zm.598 2.075c0-.168.162-.306.358-.306.196 0 .352.138.352.306v.532c0 .167-.156.305-.352.305-.196 0-.358-.138-.358-.305v-.532zm23.862 0c0-.168.162-.306.358-.306.196 0 .352.138.352.306v.532c0 .167-.156.305-.352.305-.196 0-.358-.138-.358-.305v-.532zm-2.168 0c0-.168.162-.306.358-.306.196 0 .351.138.351.306v.532c0 .167-.155.305-.351.305-.196 0-.358-.138-.358-.305v-.532zm-2.168 0c0-.168.161-.306.352-.306.196 0 .357.138.357.306v.532c0 .167-.161.305-.357.305-.19 0-.352-.138-.352-.305v-.532zm-2.168 0c0-.168.155-.306.351-.306.197 0 .358.138.358.306v.532c0 .167-.161.305-.358.305-.196 0-.351-.138-.351-.305v-.532zm-2.169 0c0-.168.156-.306.352-.306.196 0 .358.138.358.306v.532c0 .167-.162.305-.358.305-.196 0-.352-.138-.352-.305v-.532zm-2.174 0c0-.168.162-.306.358-.306.196 0 .352.138.352.306v.532c0 .167-.156.305-.352.305-.196 0-.358-.138-.358-.305v-.532zm-2.168 0c0-.168.162-.306.358-.306.196 0 .352.138.352.306v.532c0 .167-.156.305-.352.305-.196 0-.358-.138-.358-.305v-.532zm-2.168 0c0-.168.161-.306.358-.306.196 0 .351.138.351.306v.532c0 .167-.155.305-.351.305-.197 0-.358-.138-.358-.305v-.532zm-2.168 0c0-.168.155-.306.352-.306.196 0 .357.138.357.306v.532c0 .167-.161.305-.357.305-.197 0-.352-.138-.352-.305v-.532zm-2.168 0c0-.168.155-.306.351-.306.196 0 .358.138.358.306v.532c0 .167-.162.305-.358.305-.196 0-.351-.138-.351-.305v-.532zm-2.174 0c0-.168.161-.306.357-.306.196 0 .352.138.352.306v.532c0 .167-.156.305-.352.305-.196 0-.357-.138-.357-.305v-.532zm2.403 1.98v.912a.803.803 0 01-.23.564.776.776 0 01-.56.238h-3.564a.762.762 0 01-.554-.238.809.809 0 01-.235-.564v-.912h.74v.912c0 .013.007.025.013.031h.006a.033.033 0 00.03.018h3.565c.018 0 .03-.006.036-.018.012-.006.012-.018.012-.03v-.913h.74z"
      />
      <path
        fill="#396182"
        fillRule="nonzero"
        d="M22.714 29l-.016 13.714h-.67L22.046 29h.67zm-3.428 13.714L19.302 29h.664l-.01 13.714h-.67z"
      />
      <g fill="#396182" fillRule="nonzero">
        <path d="M28.384 27.857v.839c0 .197-.09.383-.227.518a.81.81 0 01-.555.22h-3.534a.81.81 0 01-.555-.22.712.712 0 01-.227-.518v-.839h.734v.839c0 .011.006.022.012.028.006.011.024.017.036.017h3.534c.012 0 .024-.006.036-.017a.042.042 0 00.012-.028v-.839h.734zM27.65 44.17v-.84a.042.042 0 00-.012-.028c-.012-.01-.024-.017-.036-.017h-3.534c-.012 0-.03.006-.036.017a.042.042 0 00-.012.028v.84h-.734v-.84a.69.69 0 01.227-.517.81.81 0 01.555-.22h3.534a.81.81 0 01.555.22c.138.13.227.315.227.517v.84h-.734z" />
        <path d="M27.703 29.084v13.858h-.734V29.084h.734zM23.96 42.942V29.084h.735v13.858h-.735z" />
      </g>
      <g fill="#396182" fillRule="nonzero">
        <path d="M33.527 27.857v.839c0 .197-.09.383-.227.518a.81.81 0 01-.555.22H29.21a.81.81 0 01-.556-.22.712.712 0 01-.226-.518v-.839h.734v.839c0 .011.006.022.012.028.006.011.024.017.036.017h3.534c.012 0 .024-.006.036-.017a.042.042 0 00.012-.028v-.839h.734zm-.734 16.313v-.84a.042.042 0 00-.012-.028c-.012-.01-.024-.017-.036-.017H29.21c-.012 0-.03.006-.036.017a.042.042 0 00-.012.028v.84h-.734v-.84c0-.202.083-.388.226-.517a.81.81 0 01.556-.22h3.534a.81.81 0 01.555.22c.137.13.227.315.227.517v.84h-.734z" />
        <path d="M32.846 29.084v13.858h-.734V29.084h.734zm-3.743 13.858V29.084h.734v13.858h-.734z" />
      </g>
      <path
        fill="#396182"
        fillRule="nonzero"
        d="M38.714 27.857v.912a.82.82 0 01-.229.564.786.786 0 01-.56.238H34.36a.786.786 0 01-.56-.238.803.803 0 01-.229-.564v-.912h.741v.912c0 .013.006.025.012.031.012.012.024.018.036.018h3.565c.012 0 .024-.006.037-.018a.047.047 0 00.012-.03v-.913h.74zm5.143 0v.912a.81.81 0 01-.234.564.782.782 0 01-.554.238h-3.567a.771.771 0 01-.553-.238.81.81 0 01-.235-.564v-.912h.74v.912c0 .013.006.025.012.031h.006a.033.033 0 00.03.018h3.567a.033.033 0 00.03-.018c.012-.006.012-.018.012-.03v-.913h.746zM22.545 44.429v-.913a.047.047 0 00-.012-.03c-.006-.013-.018-.019-.036-.019h-3.565a.033.033 0 00-.03.019.034.034 0 00-.018.03v.913h-.741v-.913c0-.22.09-.422.235-.563a.762.762 0 01.554-.239h3.565c.223 0 .415.092.56.24a.78.78 0 01.229.562v.913h-.741zm15.429 0v-.913a.047.047 0 00-.012-.03c-.013-.013-.025-.019-.037-.019H34.36c-.012 0-.024.006-.036.019a.047.047 0 00-.012.03v.913h-.74v-.913c0-.22.084-.422.228-.563a.786.786 0 01.56-.239h3.565a.803.803 0 01.79.802v.913h-.741z"
      />
      <path
        fill="#396182"
        fillRule="nonzero"
        d="M43.111 44.429v-.913c0-.012 0-.024-.012-.03a.033.033 0 00-.03-.019h-3.567a.033.033 0 00-.03.019.034.034 0 00-.018.03v.913h-.74v-.913c0-.22.09-.422.235-.563a.76.76 0 01.553-.239h3.567c.217 0 .41.092.554.24.144.14.234.342.234.562v.913h-.746zM45 25v2.122c0 .36-.153.686-.404.926a1.392 1.392 0 01-.966.38H18.935c-.375 0-.716-.146-.96-.38a1.277 1.277 0 01-.404-.926V25h.7v2.122a.62.62 0 00.198.452c.12.114.285.185.466.185H43.63c.188 0 .353-.07.472-.185a.644.644 0 00.199-.452V25H45zm-6.857 4v13.714h-.673V29h.673zm-3.429 13.714V29h.673v13.714h-.673zm8-13.714v13.714h-.672V29h.672zm-3.428 13.714V29h.672v13.714h-.672zm-22.549 2.857h29.097a.85.85 0 01.617.272h.006c.16.16.257.39.257.638v1.376H15.857v-1.376c0-.248.097-.473.257-.638a.873.873 0 01.623-.272zm29.097.727H16.737a.178.178 0 00-.125.053v.006a.163.163 0 00-.052.124v.65h29.451v-.65a.187.187 0 00-.051-.124v-.006a.19.19 0 00-.126-.053z"
      />
      <path
        fill="#396182"
        fillRule="nonzero"
        d="M17 45.571v-.907c0-.221.097-.427.258-.57h.005a.897.897 0 01.618-.237H44.12c.24 0 .457.09.618.238.16.147.263.348.263.57v.906h-.71v-.907a.153.153 0 00-.046-.11l-.005-.006a.182.182 0 00-.12-.042H17.88a.176.176 0 00-.125.047.159.159 0 00-.052.111v.907H17z"
      />
    </g>
  </svg>
);

export default ProjectIcon;
