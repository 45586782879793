import React, { SVGProps, HTMLAttributes } from "react";
import * as palette from "@govlaunch/palette";
import { Button } from "@govlaunch/core";

import { useToggle } from "react-use";
import { Margin } from "~/components/spacings";
import MakersIcon from "~/components/icons/MakersIcon";
import { NewsworthyInput } from "~/components/form/NewsworthyField";
import { FormattedMessage, useIntl } from "react-intl";

export function NoPosts({ ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      css={{
        borderRadius: 4,
        background: "#fff",
        border: `1px solid ${palette.lightestGray}`,
        padding: 24,
      }}
      {...props}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          maxWidth: 400,
          margin: "0 auto",
        }}
      >
        <PostIcon width={60} height={60} />

        <p
          css={{
            margin: "12px 0 0 0",
            color: palette.text,
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          <FormattedMessage defaultMessage="Let's get started!" id="UumDyt" />
        </p>

        <p
          css={{
            margin: "12px 0 0 0",
            color: palette.darkGray,
            fontSize: 14,
          }}
        >
          <FormattedMessage defaultMessage="What do you want to share with the innovator community?" id="WJ8VNE" />
        </p>

        <p
          css={{
            margin: 0,
            color: palette.darkGray,
            fontSize: 14,
          }}
        >
          <FormattedMessage
            defaultMessage="Post <strong>questions</strong>, <strong>resources</strong>, <strong>projects</strong> and <strong>more...</strong>" id="Wem5tL"
            values={{
              // eslint-disable-next-line
              strong: (text: string) => <strong>{text}</strong>,
            }}
          />
        </p>
      </div>
    </div>
  );
}

export function NoSaved({ ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      css={{
        borderRadius: 4,
        background: "#fff",
        border: `1px solid ${palette.lightestGray}`,
        padding: 24,
      }}
      {...props}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          maxWidth: 400,
          margin: "0 auto",
        }}
      >
        <SavedIcon width={60} height={60} />

        <p
          css={{
            margin: "12px 0 0 0",
            color: palette.text,
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          <FormattedMessage defaultMessage="Save something" id="uOgvte" />
        </p>

        <p
          css={{
            margin: "12px 0 0 0",
            color: palette.darkGray,
            fontSize: 14,
          }}
        >
          <FormattedMessage defaultMessage="Find an interesting post, project, product or resource? Click on the bookmark icon to save it here!" id="4kHjwv" />
        </p>
      </div>
    </div>
  );
}

interface INoNewsworthyProps {
  onAddNewsworthy: (newsworthy: any) => void;
}

export function NoNewsworthy({ onAddNewsworthy, ...props }: INoNewsworthyProps & HTMLAttributes<HTMLDivElement>) {
  const intl = useIntl();
  const [isAdding, toggle] = useToggle(false);

  return (
    <div
      css={{
        borderRadius: 4,
        background: "#fff",
        border: `1px solid ${palette.lightestGray}`,
      }}
      data-intercom-target="Group Newsworthy"
      {...props}
    >
      <div
        css={{
          padding: 24,
        }}
      >
        {isAdding && (
          <Margin mb={12}>
            <NewsworthyInput
              placeholder={intl.formatMessage({
                defaultMessage: 'Add a news article link and press Enter... https://', id: 'hIt47Y',
              })}
              onChange={(newsworthy: any) => onAddNewsworthy(newsworthy)}
              padding={0}
            />
          </Margin>
        )}

        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            maxWidth: 400,
            margin: "0 auto",
          }}
        >
          <NewsIcon width={60} height={60} />

          <p
            css={{
              margin: "12px 0 0 0",
              color: palette.text,
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            <FormattedMessage defaultMessage="Share Updates" id="fC4Ovd" />
          </p>

          <p
            css={{
              margin: "12px 0 0 0",
              color: palette.darkGray,
              fontSize: 14,
            }}
          >
            <FormattedMessage defaultMessage="Add news articles and other updates about your projects or organization." id="UBM2pY" />
          </p>

          <Button
            size="small"
            color={palette.innovatorBlue}
            theme="secondary"
            onClick={toggle}
            css={{
              margin: "12px 0 0 0",
              minWidth: 150,
            }}
          >
            <FormattedMessage defaultMessage="Share Article" id="/GyMKa" />
          </Button>
        </div>
      </div>
    </div>
  );
}

interface INoSharingPageProps {
  onStartSharing: () => void;
  isForMakers?: boolean;
}

export function NoSharingPage({
  onStartSharing,
  isForMakers,
  ...props
}: INoSharingPageProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      css={{
        borderRadius: 4,
        background: "#fff",
        border: `1px solid ${palette.lightestGray}`,
      }}
      {...props}
    >
      <div
        css={{
          fontSize: 12,
          textTransform: "uppercase",
          color: palette.darkGray,
          fontWeight: 500,
          padding: 16,
          borderBottom: `1px solid ${palette.lightestGray}`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <FormattedMessage defaultMessage="Sharing" id="XfKNCg" />
        {isForMakers && false && (
          <Margin ml={5}>
            <MakersIcon width={20} height={20} />
          </Margin>
        )}
      </div>

      <div
        css={{
          padding: 24,
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            maxWidth: 400,
            margin: "0 auto",
          }}
        >
          <ShareIcon width={60} height={60} />

          <p
            css={{
              margin: "12px 0 0 0",
              color: palette.text,
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            <FormattedMessage defaultMessage="Activate your Share Page" id="uK/HJT" />
          </p>

          <p
            css={{
              margin: "12px 0 0 0",
              color: palette.darkGray,
              fontSize: 14,
            }}
          >
            <FormattedMessage defaultMessage="Enable sharing to create a customizable public page to showcase your projects and more!" id="qvQxto" />
          </p>

          <Button
            size="small"
            color={palette.innovatorBlue}
            theme="secondary"
            onClick={onStartSharing}
            css={{
              margin: "12px 0 0 0",
              minWidth: 150,
            }}
          >
            <FormattedMessage defaultMessage="Start Sharing" id="WW+hXh" />
          </Button>
        </div>
      </div>
    </div>
  );
}

interface INoResourcesProps {
  onAddResource: () => void;
  innerRef: any;
}

export function NoResources({ onAddResource, innerRef, ...props }: INoResourcesProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      css={{
        borderRadius: 4,
        background: "#fff",
        border: `1px solid ${palette.lightestGray}`,
      }}
      {...props}
    >
      <div
        css={{
          fontSize: 12,
          textTransform: "uppercase",
          color: palette.darkGray,
          fontWeight: 500,
          padding: 16,
          borderBottom: `1px solid ${palette.lightestGray}`,
        }}
      >
        <FormattedMessage defaultMessage="Resources" id="c/KktL" />
      </div>
      <div
        css={{
          padding: 24,
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            maxWidth: 400,
            margin: "0 auto",
          }}
        >
          <StackIcon width={60} height={60} />

          <p
            css={{
              margin: "12px 0 0 0",
              color: palette.text,
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            <FormattedMessage defaultMessage="Share Resources" id="HcaWQZ" />
          </p>

          <p
            css={{
              margin: "12px 0 0 0",
              color: palette.darkGray,
              fontSize: 14,
            }}
          >
            <FormattedMessage defaultMessage="Share useful resources like open source code, planning documents, etc." id="FNn0A5" />
          </p>

          <div ref={innerRef}>
            <Button
              size="small"
              color={palette.innovatorBlue}
              theme="secondary"
              onClick={onAddResource}
              css={{
                margin: "12px 0 0 0",
                minWidth: 150,
              }}
            >
              <FormattedMessage defaultMessage="Add Resource" id="A0sFkU" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

interface INoAwardsProps {
  onAddAwards: () => void;
}

export function NoAwards({ onAddAwards, ...props }: INoAwardsProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      css={{
        background: "#fff",
        borderRadius: 4,
        border: `1px solid ${palette.lightestGray}`,
      }}
      {...props}
    >
      <div
        css={{
          padding: 16,
          display: "flex",
          borderBottom: `1px solid ${palette.lightestGray}`,
        }}
      >
        <span
          css={{
            fontSize: 12,
            fontWeight: 500,
            color: palette.darkGray,
            textTransform: "uppercase",
          }}
        >
          <FormattedMessage defaultMessage="🏅 Awards" id="RT1ZpG" />
        </span>
      </div>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          maxWidth: 400,
          margin: "0 auto",
          padding: 24,
        }}
      >
        <AwardsIcon width={60} height={60} />

        <p
          css={{
            margin: "12px 0 0 0",
            color: palette.text,
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          <FormattedMessage defaultMessage="Add Awards" id="HldPvv" />
        </p>

        <p
          css={{
            margin: "12px 0 0 0",
            color: palette.darkGray,
            fontSize: 14,
          }}
        >
          <FormattedMessage defaultMessage="Share useful awards..." id="RSObAr" />
        </p>

        <div>
          <Button
            size="small"
            color={palette.innovatorBlue}
            theme="secondary"
            onClick={onAddAwards}
            css={{
              margin: "12px 0 0 0",
              minWidth: 150,
            }}
          >
            <FormattedMessage defaultMessage="Add Awards" id="HldPvv" />
          </Button>
        </div>
      </div>
    </div>
  );
}

interface INoProductsProps {
  onAddProduct: () => void;
}

export function NoProducts({ onAddProduct, ...props }: INoProductsProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      css={{
        borderRadius: 4,
        background: "#fff",
        border: `1px solid ${palette.lightestGray}`,
      }}
      {...props}
    >
      <div
        css={{
          fontSize: 12,
          textTransform: "uppercase",
          color: palette.darkGray,
          fontWeight: 500,
          padding: 16,
          borderBottom: `1px solid ${palette.lightestGray}`,
        }}
      >
        <FormattedMessage defaultMessage="Products" id="7NFfmz" />
      </div>
      <div
        css={{
          padding: 24,
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            maxWidth: 400,
            margin: "0 auto",
          }}
        >
          <ResourceIcon width={60} height={60} />

          <p
            css={{
              margin: "12px 0 0 0",
              color: palette.text,
              fontWeight: 500,
              fontSize: 16,
            }}
          >
            <FormattedMessage defaultMessage="Build your product stack" id="zbw64e" />
          </p>

          <p
            css={{
              margin: "12px 0 0 0",
              color: palette.darkGray,
              fontSize: 14,
            }}
          >
            <FormattedMessage defaultMessage="Share what products and tools you're using to innovate your community." id="0cTs7/" />
          </p>

          <Button
            size="small"
            color={palette.innovatorBlue}
            theme="secondary"
            onClick={onAddProduct}
            css={{
              margin: "12px 0 0 0",
              minWidth: 150,
            }}
          >
            <FormattedMessage defaultMessage="Add Tools" id="+yfT26" />
          </Button>
        </div>
      </div>
    </div>
  );
}

const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={62} height={62} viewBox="0 0 62 62" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#DAEAF6"
        stroke="#005AEE"
        strokeWidth={1.25}
        d="M30.8 1.2c16.367 0 29.629 13.261 29.629 29.621 0 16.368-13.262 29.629-29.63 29.629-16.36 0-29.62-13.261-29.62-29.629 0-16.36 13.26-29.621 29.62-29.621z"
      />
      <path fill="#EAEAEA" fillRule="nonzero" d="M17.03 39.66h-5.784V22.282h5.784z" />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M50.756 40.139H11.244V22.28h39.512"
      />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M50.756 36.595H22.11V26.11h28.646m-30.762 5.242a2.969 2.969 0 01-2.964 2.976 2.969 2.969 0 01-2.963-2.976 2.969 2.969 0 012.963-2.976 2.969 2.969 0 012.964 2.976z"
      />
      <path
        fill="#2C426B"
        fillRule="nonzero"
        d="M25.175 28.34h.664v2.285h.015c.105-.189.27-.355.475-.468.197-.115.429-.19.678-.19.49 0 1.273.303 1.273 1.567v2.179h-.662v-2.105c0-.588-.22-1.09-.846-1.09-.429 0-.766.303-.887.668a.776.776 0 00-.046.317v2.21h-.664V28.34zm4.948.658v1.053h.949v.505h-.95v1.976c0 .455.129.71.497.71.174 0 .302-.022.387-.043l.028.499c-.128.053-.33.09-.587.09-.31 0-.557-.098-.716-.28-.188-.196-.257-.521-.257-.954v-1.998h-.564v-.505h.564v-.878l.649-.175zm2.492 0v1.053h.95v.505h-.95v1.976c0 .455.128.71.497.71.173 0 .301-.022.385-.043l.03.499c-.13.053-.331.09-.588.09-.309 0-.557-.098-.716-.28-.187-.196-.256-.521-.256-.954v-1.998h-.564v-.505h.564v-.878l.648-.175zm2.349 3.224a1.026 1.026 0 001.025 1.053c.7 0 1.108-.576 1.108-1.416 0-.734-.384-1.362-1.085-1.362-.452 0-.874.324-1.002.825a1.107 1.107 0 00-.046.272v.628zm-.656-.977c0-.468-.014-.846-.03-1.194h.596l.03.628h.014c.272-.448.703-.712 1.297-.712.882 0 1.544.75 1.544 1.862 0 1.316-.799 1.968-1.657 1.968-.481 0-.905-.213-1.123-.576h-.015v1.99h-.656v-3.966zm4.195 1.787c.196.13.543.264.874.264.482 0 .707-.241.707-.543 0-.32-.187-.494-.678-.674-.654-.235-.964-.598-.964-1.037 0-.591.476-1.075 1.26-1.075.368 0 .692.105.896.227l-.167.485a1.404 1.404 0 00-.747-.214c-.39 0-.61.229-.61.5 0 .304.22.44.695.621.633.243.955.56.955 1.106 0 .642-.496 1.097-1.362 1.097-.4 0-.77-.1-1.025-.251l.166-.506zm3.203.983l2.102-5.486h.513l-2.11 5.486zm2.582 0l2.102-5.486h.513l-2.11 5.486z"
      />
      <path
        fill="#005AEE"
        fillRule="nonzero"
        d="M25.966 38.869l-1.445 1.85-3.537-3.655-2.05 2.135L17 31.714l7.376 1.83-1.913 1.991c1.104 1.151 2.398 2.184 3.503 3.334z"
      />
    </g>
  </svg>
);

const ResourceIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={61} height={61} viewBox="0 0 61 61" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#DAEAF6"
        stroke="#005AEE"
        strokeWidth={0.714}
        d="M30.8 1.2c16.367 0 29.629 13.261 29.629 29.621 0 16.368-13.262 29.629-29.63 29.629-16.36 0-29.62-13.261-29.62-29.629 0-16.36 13.26-29.621 29.62-29.621z"
      />
      <path
        fill="#EAEAEA"
        fillRule="nonzero"
        d="M29.32 39.983V18.14H27.3v26.585h8.08v-1.951h-3.245c-1.554 0-2.815-1.25-2.815-2.79zm-10.014 0V18.14h-2.02v26.585h8.079v-1.951H22.12c-1.555 0-2.814-1.25-2.814-2.79z"
      />
      <path fill="#0091FC" fillRule="nonzero" d="M25.365 26.742h-8.08V23.42h8.08z" />
      <path fill="#0770BF" fillRule="nonzero" d="M35.38 26.742H27.3V23.42h8.08z" />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.143}
        d="M17.286 31.755V18.14h8.079v26.585h-8.079V37.77m0-14.351h8.079m-8.079 3.323h4.04m2.561 0h1.478"
      />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.143}
        d="M23.493 39.92c0 1.186-.97 2.149-2.168 2.149a2.16 2.16 0 01-2.167-2.15 2.16 2.16 0 012.167-2.15 2.16 2.16 0 012.168 2.15zm11.887 4.805H27.3V18.14h8.08zM27.3 23.419h8.08m-8.08 3.323h4.04"
      />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.143}
        d="M33.508 39.92c0 1.186-.97 2.149-2.167 2.149a2.16 2.16 0 01-2.169-2.15 2.16 2.16 0 012.169-2.15 2.16 2.16 0 012.167 2.15zm.398-13.178h1.474"
      />
      <path
        fill="#EAEAEA"
        fillRule="nonzero"
        d="M39.797 39.974V18.132h-2.02v26.585h8.08v-1.951h-3.245c-1.555 0-2.815-1.25-2.815-2.792z"
      />
      <path fill="#B0DDFC" fillRule="nonzero" d="M45.857 26.733h-8.08V23.41h8.08z" />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.143}
        d="M45.857 44.717h-8.08V18.132h8.08zm-8.08-21.307h8.08m-8.08 3.323h4.04"
      />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.143}
        d="M43.985 39.91a2.16 2.16 0 01-2.168 2.15 2.16 2.16 0 01-2.168-2.15c0-1.186.97-2.15 2.168-2.15a2.16 2.16 0 012.168 2.15zm.398-13.177h1.473"
      />
      <path d="M16.143 16h29.714v29.714H16.143z" />
    </g>
  </svg>
);

function AwardsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width={100} height={100} viewBox="0 0 100 100" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle stroke="#005AEE" strokeWidth={1.177} fill="#DAEAF6" cx={49.808} cy={49.808} r={48.954} />
        <g>
          <path
            d="M57.742 42.416a8.88 8.88 0 01-8.882 8.882 8.879 8.879 0 01-8.88-8.882 8.878 8.878 0 018.88-8.88 8.88 8.88 0 018.882 8.88z"
            fill="#0091FC"
            fillRule="nonzero"
          />
          <path
            fill="#EAEAEA"
            fillRule="nonzero"
            d="M29.141 66.375l-5.297 7.062 11.894-2.636 6.19 11.282 1.333-8.96-4.833-8.805zM69.892 64.643l6.263 6.223-12.151-.89-4.496 12.061-2.613-8.673 3.511-9.415z"
          />
          <path
            d="M51.535 26.1h0a3.367 3.367 0 003.514.94 3.367 3.367 0 014.31 2.49 3.365 3.365 0 002.573 2.571 3.368 3.368 0 012.488 4.313 3.366 3.366 0 00.943 3.511 3.37 3.37 0 010 4.982 3.366 3.366 0 00-.943 3.511 3.367 3.367 0 01-2.488 4.314 3.367 3.367 0 00-2.572 2.57 3.365 3.365 0 01-4.311 2.489 3.376 3.376 0 00-3.514.941 3.364 3.364 0 01-4.98 0 3.371 3.371 0 00-3.51-.941 3.366 3.366 0 01-4.313-2.49 3.367 3.367 0 00-2.57-2.57 3.37 3.37 0 01-2.493-4.313 3.364 3.364 0 00-.939-3.51 3.37 3.37 0 010-4.983 3.364 3.364 0 00.939-3.51A3.37 3.37 0 0136.16 32.1a3.366 3.366 0 002.57-2.57 3.368 3.368 0 014.314-2.49 3.362 3.362 0 003.51-.941 3.369 3.369 0 014.98 0z"
            stroke="#2C426B"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55.553 36.578c-2.311-2.715-5.32-3.041-6.508-3.041a8.879 8.879 0 00-8.88 8.879 8.88 8.88 0 008.88 8.882 8.88 8.88 0 008.882-8.882M37.683 54.988l-13.84 18.448L35.739 70.8l6.193 11.285 2.61-20.108M59.36 55.301L76.156 71.11l-12.174-.562-4.167 12.177-6.502-19.019"
            stroke="#2C426B"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

const NewsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={61} height={61} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#DAEAF6"
        stroke="#005AEE"
        strokeWidth={0.721}
        d="M30.585 1.199C46.835 1.199 60 14.368 60 30.614c0 16.255-13.165 29.424-29.415 29.424-16.242 0-29.408-13.17-29.408-29.424C1.177 14.368 14.343 1.2 30.585 1.2z"
      />
      <path fill="#0770BF" fillRule="nonzero" d="M18.733 43.65H15.52v-3.204h3.213z" />
      <path fill="#0091FC" fillRule="nonzero" d="M38.336 43.65H18.733v-3.204h19.603z" />
      <path fill="#EAEAEA" fillRule="nonzero" d="M18.733 40.446H15.52V17.65h3.213z" />
      <path fill="#B0DDFC" fillRule="nonzero" d="M41.55 43.65h-3.214v-3.204h3.214z" />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.154}
        d="M41.55 27.056V43.65H15.466v-26H41.55v6.21"
      />
      <path
        fill="#2C426B"
        fillRule="nonzero"
        d="M23.78 26.255h-1.15l-1.702-2.98v2.98h-1.16v-4.787h1.16l1.698 2.978v-2.978h1.155zm3.712-2.032h-1.82v1.145h2.15v.887h-3.309v-4.787h3.315v.89h-2.156v1.01h1.82zm4.523.246l.55-3.001h1.148l-1.02 4.786h-1.2l-.643-2.728-.63 2.728h-1.198L28 21.468h1.151l.55 3 .66-3h.983zm4.627.513c0-.17-.06-.3-.179-.394-.12-.094-.33-.192-.632-.292a5.991 5.991 0 01-.739-.294c-.623-.304-.934-.723-.934-1.256 0-.265.078-.499.233-.702.155-.203.375-.36.658-.473.284-.114.602-.169.956-.169.345 0 .656.061.93.184.273.123.485.297.637.524.152.227.227.486.227.778h-1.154c0-.195-.06-.346-.18-.455-.119-.107-.281-.16-.486-.16-.207 0-.37.045-.49.136a.421.421 0 00-.18.348c0 .122.066.233.198.332.133.1.365.203.697.31.332.107.604.221.817.344.52.298.778.709.778 1.232 0 .418-.158.748-.474.987-.316.239-.751.359-1.303.359a2.58 2.58 0 01-1.057-.21c-.316-.139-.554-.33-.712-.571a1.49 1.49 0 01-.24-.838h1.161c0 .258.067.446.2.568.133.121.348.183.648.183.192 0 .342-.042.453-.124a.407.407 0 00.167-.347z"
      />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.154}
        d="M17.906 29.808H39.11m0 2.619H17.908m-.002 2.619h8.257m0 3.18h-8.257m21.204 0h-9.194m0-3.18h9.194m4.334 4.578l.015-10.221a.939.939 0 01.94-.932h.195a.94.94 0 01.94.937V40.78a2.82 2.82 0 01-2.82 2.81H41.55"
      />
      <path d="M14.899 15.328h31.202V46.54H14.899z" />
    </g>
  </svg>
);

const StackIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={61} height={62} viewBox="0 0 62 62" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#DAEAF6"
        stroke="#005AEE"
        strokeWidth={1.25}
        d="M30.3 1.45c16.367 0 29.629 13.261 29.629 29.621 0 16.368-13.262 29.629-29.63 29.629C13.94 60.7.68 47.439.68 31.071.68 14.711 13.94 1.45 30.3 1.45z"
      />
      <path
        fill="#EAEAEA"
        fillRule="nonzero"
        d="M18.431 32.7H15.96v13.248h13.247v-2.32h-6.275a4.5 4.5 0 01-4.5-4.5V32.7z"
      />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M28.896 42.284v3.664H15.648V32.7h13.248v4.086"
      />
      <path
        fill="#EAEAEA"
        fillRule="nonzero"
        d="M34.724 32.7h-2.471v13.248H45.5v-2.32h-6.275a4.5 4.5 0 01-4.501-4.5V32.7z"
      />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M45.188 42.284v3.664H31.941V32.7h13.247v4.086"
      />
      <path
        fill="#EAEAEA"
        fillRule="nonzero"
        d="M26.591 16.7H24.12v13.25h13.248v-2.32h-6.275a4.5 4.5 0 01-4.5-4.5V16.7z"
      />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M37.056 26.285v3.664H23.808V16.7h13.248v4.085"
      />
      <path
        fill="#0091FC"
        fillRule="nonzero"
        d="M29.8 24.39h1.56l-.783-2.52-.778 2.52zm1.98 1.35h-2.4l-.419 1.36h-1.87l2.664-7.266H31.4l2.684 7.265h-1.88l-.426-1.358zm10.438 14.468a2.642 2.642 0 01-.409 1.339c-.25.387-.6.687-1.05.9-.45.214-.966.32-1.544.32-.955 0-1.706-.311-2.256-.933-.548-.622-.822-1.5-.822-2.634v-.36c0-.712.122-1.335.37-1.87a2.825 2.825 0 011.07-1.236c.467-.29 1.005-.436 1.618-.436.882 0 1.589.232 2.125.695.536.465.84 1.104.913 1.919h-1.746c-.013-.442-.125-.76-.334-.953-.21-.192-.53-.289-.958-.289-.436 0-.755.162-.958.489-.203.326-.31.847-.32 1.563v.512c0 .776.097 1.33.292 1.661.194.334.53.5 1.006.5.402 0 .71-.095.922-.284.214-.19.327-.491.34-.903h1.741zm-20.853-.629v1.741h1.147c.317 0 .559-.073.73-.218.169-.144.253-.347.253-.61 0-.606-.3-.91-.903-.913h-1.227zm0-1.148h.912c.383-.003.655-.073.82-.21.162-.136.244-.339.244-.606 0-.307-.09-.529-.265-.663-.176-.134-.46-.202-.853-.202h-.858v1.681zm-1.751 4.236v-7.264h2.609c.934 0 1.645.172 2.133.514.487.342.73.84.73 1.49 0 .378-.085.698-.258.964a1.526 1.526 0 01-.764.589c.379.1.671.287.873.558.203.273.304.606.304 1 0 .712-.225 1.246-.675 1.602-.451.359-1.12.54-2.004.547h-2.948z"
      />
      <path d="M14.5 15.25h31v31h-31z" />
    </g>
  </svg>
);

const SavedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={100} height={100} viewBox="0 0 100 100" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={49.808} cy={49.808} r={48.954} fill="#DAEAF6" stroke="#005AEE" strokeWidth={1.177} />
      <path fill="#EAEAEA" fillRule="nonzero" d="M23.533 51.054h6.073v19.198h-6.073z" />
      <path fill="#0091FC" fillRule="nonzero" d="M69.082 51.67H23.364v-7.61h18.078l3.632 3.633h24.008z" />
      <path fill="#0770BF" fillRule="nonzero" d="M23.742 44.077h5.864v7.573h-5.864z" />
      <path fill="#B0DDFC" fillRule="nonzero" d="M61.988 48.227h6.622v3.443h-6.622z" />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.834}
        d="M56.37 70.336H23.28V43.374h16.892l4.059 4.061h24.851v9.731M29.125 38.177l4.55-13.644 32.483 9.746-1.95 9.096M36.923 31.03l23.39 6.822m-25.015 0l22.741 5.524"
      />
      <path
        stroke="#2C426B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.834}
        d="M75.635 68.314a7.663 7.663 0 11-15.325.001 7.663 7.663 0 0115.325 0zm-7.661-4.412v8.967m4.482-4.483H63.49"
      />
      <path d="M16.765 17.563H82.15v65.384H16.765z" />
    </g>
  </svg>
);

const PostIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={100} height={100} viewBox="0 0 100 100" {...props}>
    <g fill="none" fillRule="evenodd">
      <circle cx={49.808} cy={49.808} r={48.954} fill="#DAEAF6" stroke="#005AEE" strokeWidth={1.177} />
      <g transform="translate(29 28)">
        <path
          stroke="#2C426B"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M19.06 44.17H.016V.657h38.306v17.817"
        />
        <path
          fill="#EBEBEB"
          fillRule="nonzero"
          d="M23.958 36.798l-2.895 2.071.415 1.727 5.873 5.873 6.448-.17 5.15-5.15c-1.08.142-2.11-.435-3.11.558l-1.908 2.025c-.5.496-1.18.777-1.887.774l-3.42-.014a1.389 1.389 0 01-.97-.4l-3.641-3.89a1.98 1.98 0 01-.374-2.31l.319-1.094z"
        />
        <path
          stroke="#0091FC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M23.863 36.704s-4.885 2.092-2.094 4.185c1.398 1.398 2.789 2.77 4.147 4.206 1.648 1.74 2.268 1.411 4.507 1.377 1.293-.02 2.608-.003 3.903-.003m11.164-11.16c-2.512-4.143-5.301-7.674-8.48-11.314-1.412-1.618-2.286-2.2-4.446-1.527-2.809.94-6.063 3.8-8.168 5.869l-.485.479m3.422 11.357l-3.489-3.49m11.182 1.415l-4.885-4.884m8.373 1.396l-4.886-4.883"
        />
        <path
          stroke="#0091FC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M40.609 39.925l-6.414 6.414 3.271 3.27 5.349-5.348 3.488-3.487 2.325-2.328-3.269-3.27-2.057 2.056m-19.439-.528l-7.835-8.264a2.665 2.665 0 010-3.754 2.664 2.664 0 013.754 0l4.128 4.13M6.006 16.31V6.435h2.47a2.476 2.476 0 012.468 2.47 2.477 2.477 0 01-2.468 2.468h-2.47"
        />
        <rect
          width={4.808}
          height={9.571}
          x={13.748}
          y={6.434}
          stroke="#0091FC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          rx={2.404}
        />
        <path
          stroke="#0091FC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M26.273 8.892a2.465 2.465 0 00-2.457-2.458c-2.223 0-3.323 2.891-1.588 4.325 1.169.968 2.822.167 3.74 1.87.888 1.648-.256 3.585-2.152 3.633a2.464 2.464 0 01-2.458-2.457m10.573-7.371V16m-2.853-9.566h5.707"
        />
      </g>
    </g>
  </svg>
);
