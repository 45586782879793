import ProfilePicture from "@govlaunch/profile-picture";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import IntlRelativeTime from "~/src/shared/components/IntlRelativeTime";
import { IGovernment } from "~/types/types";
import FeedCard from "~/components/feed/card/FeedCard";

interface IGovernmentJoinedGroupFeedItem {
  government: Pick<IGovernment, "_id" | "name" | "slug" | "logo">;
  createdAt: any;
}

const GovernmentJoinedGroupFeedItem = ({ government, createdAt }: IGovernmentJoinedGroupFeedItem) => {
  const index = Math.round(Math.random() * 1);
  const background = ["/static/feed/blue-background.png", "/static/feed/purple-background.png"][index];

  return (
    <FeedCard
      css={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link
          href="/governments/[governmentSlug]/[[...content]]"
          as={`/governments/${government.slug}`}
          passHref={true}
        >
          <a
            css={{
              display: "flex",
            }}
          >
            <ProfilePicture size={45} image={government.logo} name={government.name} />
          </a>
        </Link>

        <div
          css={{
            marginLeft: 12,
          }}
        >
          <p
            css={{
              color: "#fff",
              fontSize: 15,
            }}
          >
            <Link
              href="/governments/[governmentSlug]/[[...content]]"
              as={`/governments/${government.slug}`}
              passHref={true}
            >
              <a
                css={{
                  color: "#fff",
                  fontWeight: 600,
                  ":hover": {
                    textDecoration: "underline !important",
                  },
                }}
              >
                {government.name}
              </a>
            </Link>

            <span>
              {" "}
              <FormattedMessage defaultMessage="joined this group" id="bHJLnv" />{" "}
            </span>
          </p>

          <p
            css={{
              color: "#fff",
              margin: "8px 0 0",
              fontSize: 14,
            }}
          >
            <IntlRelativeTime value={createdAt} />
          </p>
        </div>
      </div>
    </FeedCard>
  );
};

export default GovernmentJoinedGroupFeedItem;
