import * as palette from "@govlaunch/palette";
import Link from "next/link";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import CreateProjectModal from "~/src/components/project/CreateProjectModal";
import { ICompany, IGovernment, IGovernmentUser, IGroup, IPendingUser, IVendorUser, Maybe } from "~/types/types";
import CreateIcon from "~/components/icons/CreateIcon";
import UserProfileIcon from "~/components/icons/UserProfileIcon";
import mediaQueries from "~/components/mediaQueries";
import PostForm from "~/components/post/PostForm";
import AddProjectContentBox from "~/components/feed/AddProjectContentBox";
import { FeedContext } from "~/components/feed/interfaces";

type UserGovernment = Maybe<Pick<IGovernment, "_id" | "slug" | "name" | "logo">>;
type UserCompany = Maybe<Pick<ICompany, "_id" | "slug" | "name" | "logo">>;

type User =
  | ({ __typename: "GovernmentUser" } & Pick<IGovernmentUser, "_id" | "slug" | "fullName" | "avatar"> & {
        government: UserGovernment;
      })
  | ({ __typename: "VendorUser" } & Pick<IVendorUser, "_id" | "slug" | "fullName" | "avatar"> & {
        company: UserCompany;
      })
  | ({ __typename: "PendingUser" } & Pick<IPendingUser, "_id" | "slug" | "fullName" | "avatar">);

interface IWriteContentBoxProps {
  group?: Pick<IGroup, "_id" | "name">;
  user: User;
  context?: FeedContext | null;
  dataIntercomTarget?: string | null;
}

export default function WriteContentBox({ group, user, context, dataIntercomTarget }: IWriteContentBoxProps) {
  const [showCreateProjectModal, setShowCreateProjectModal] = useState<boolean>(false);

  if (!user) {
    return null;
  }

  return (
    <div
      data-intercom-target={dataIntercomTarget}
      css={{
        background: "#fff",
        border: `1px solid ${palette.lightestGray}`,
        borderRadius: 4,
        position: "relative",
        zIndex: 301,
      }}
    >
      <CreateProjectModal
        isOpen={showCreateProjectModal}
        onClose={() => setShowCreateProjectModal(false)}
        groupId={group?._id}
      />

      <div
        css={{
          display: "flex",
        }}
      >
        <div
          css={mediaQueries({
            color: palette.darkGray,
            fontWeight: 500,
            width: "100%",
            background: "#ebf4fa",
            borderRight: `1px solid ${palette.lightestGray}`,
            height: 48,
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: [11, 11, 12, 12],
            textTransform: "uppercase",
          })}
        >
          <CreateIcon />

          <span
            css={{
              marginLeft: 4,
            }}
          >
            <FormattedMessage defaultMessage="Create Post" id="3gMvAC" />
          </span>
        </div>

        <AddProjectContentBox onClick={() => setShowCreateProjectModal(true)} groupId={group?._id} context={context} />

        {context !== "user" && (
          <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${user.slug}`}>
            <a
              role="button"
              css={mediaQueries({
                color: palette.darkGray,
                fontWeight: 500,
                width: "100%",
                background: "#fff",
                cursor: "pointer",
                height: 48,
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: [11, 11, 12, 12],
                textTransform: "uppercase",
                border: `1px solid transparent`,
                ":hover": {
                  border: `1px solid #9ac4e1`,
                },
              })}
            >
              <UserProfileIcon />

              <span
                css={{
                  marginLeft: 4,
                }}
              >
                <FormattedMessage defaultMessage="My Activity" id="XqHKCc" />
              </span>
            </a>
          </Link>
        )}
      </div>
      <div
        css={{
          borderTop: `1px solid ${palette.lightestGray}`,
        }}
      >
        <PostForm
          government={getPostFormGovernment(group, user)}
          vendor={getPostFormVendor(group, user)}
          group={group}
        />
      </div>
    </div>
  );
}

function getPostFormGovernment(group: Pick<IGroup, "_id"> | undefined, user: IWriteContentBoxProps["user"]) {
  if (group) {
    return undefined;
  }

  if (user && user.__typename === "GovernmentUser" && user.government) {
    return user.government;
  }

  return undefined;
}

function getPostFormVendor(group: Pick<IGroup, "_id"> | undefined, user: IWriteContentBoxProps["user"]) {
  if (group) {
    return undefined;
  }

  if (user && user.__typename === "VendorUser" && user.company) {
    return user.company;
  }

  return undefined;
}
