import React from "react";
import * as palette from "@govlaunch/palette";

interface IFeedCard {
  style?: React.CSSProperties;
}

const FeedCard: React.FunctionComponent<IFeedCard> = ({ children, ...props }) => {
  return (
    <div
      css={{
        backgroundColor: palette.white,
        borderRadius: 4,
        border: `solid 1px ${palette.lightestGray}`,
        padding: 16,
        transition: "all .25s",
        position: "relative",
        "&:hover": {
          borderColor: "#9ac4e1",
        },
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default FeedCard;
