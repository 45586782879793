import { Mutation } from "@apollo/client/react/components";
import React, { ReactElement, ReactNode, useState, createRef } from "react";
import { Form, Field } from "react-final-form";
import ProfilePicture from "@govlaunch/profile-picture";
import { useSelfie } from "~/components/auth/Auth";
import LinksToolbarIcon from "~/components/post/icons/LinksToolbarIcon";
import ImageToolbarIcon from "~/components/post/icons/ImageToolbarIcon";
import DocumentToolbarIcon from "~/components/post/icons/DocumentToolbarIcon";
import { Hover } from "react-powerplug";
import Popover from "@govlaunch/popover";
import * as palette from "@govlaunch/palette";
import { Margin } from "~/components/spacings";
import OutsideClickHandler from "react-outside-click-handler";
import { Filestack, Button } from "@govlaunch/core";
import * as Yup from "yup";
import RemoveButton from "~/components/post/RemoveButton";
import validate from "~/lib/utils/validate";
import LinkField from "~/components/post/fields/LinkField";
import DocumentCard from "~/components/post/DocumentCard";
import {
  IPostToGovernmentMutation,
  IPostToGovernmentMutationVariables,
} from "~/lib/mutations/__generated__/PostToGovernment.generated";
import {
  IPostToGroupMutation,
  IPostToGroupMutationVariables,
} from "~/lib/mutations/__generated__/PostToGroup.generated";
import { IEditPostMutation, IEditPostMutationVariables } from "~/lib/mutations/__generated__/EditPost.generated";
import { convertValuesToVariables } from "~/components/post/utils";
import PostToGovernment from "~/lib/mutations/PostToGovernment";
import PostToGroup from "~/lib/mutations/PostToGroup";
import EditPost from "~/lib/mutations/EditPost";
import { IGroup, IGovernment, ICompany } from "~/types/types";
import FieldValidationError from "~/components/form/FieldValidationError";
import TextareaAutosize from "react-textarea-autosize";
import { createPortal } from "react-dom";
import LinkCard from "~/components/post/LinkCard";
import PostToVendor from "~/lib/mutations/PostToVendor";
import {
  IPostToVendorMutation,
  IPostToVendorMutationVariables,
} from "~/lib/mutations/__generated__/PostToVendor.generated";
import { FormattedMessage, useIntl } from "react-intl";
import useIntlValidationMessages from "~/lib/hooks/useIntlValidationMessages";
import { omit } from "lodash/fp";

interface IToolbarItem {
  active?: boolean;
  tooltip: string;
  onClick?: () => any;
  children: ReactNode;
}

const POST_INITIAL_STATE = {
  links: [],
  documents: [],
  images: [],
  audience: "community",
};

interface IPostFormProps {
  initialValues?: any;
  group?: Pick<IGroup, "_id" | "name">;
  government?: Pick<IGovernment, "_id" | "name">;
  vendor?: Pick<ICompany, "_id" | "name">;
  onDelete?: () => void;
}

export default function PostForm({ government, group, vendor, initialValues = POST_INITIAL_STATE }: IPostFormProps) {
  const intl = useIntl();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isAddingLink, setIsAddingLink] = useState<boolean>(false);
  const user = useSelfie();
  const formRef = createRef<HTMLFormElement>();
  const intlValidationMessages = useIntlValidationMessages();

  const validationSchema = Yup.object().shape({
    body: Yup.string()
      .required(intlValidationMessages.cantBeBlank)
      .min(3, intlValidationMessages.minCharacters(3))
      .max(280, intlValidationMessages.maxCharacters(200)),
  });

  if (!initialValues._id && ((!government && !group && !vendor) || (government && group && vendor))) {
    throw new Error("Please specify either `government`, `vendor` or `group`");
  }

  if (!user) {
    return null;
  }

  return (
    <>
      <Mutation<IPostToVendorMutation, IPostToVendorMutationVariables> mutation={PostToVendor}>
        {(postToVendor) => (
          <Mutation<IPostToGovernmentMutation, IPostToGovernmentMutationVariables> mutation={PostToGovernment}>
            {(postToGovernment) => (
              <Mutation<IPostToGroupMutation, IPostToGroupMutationVariables> mutation={PostToGroup}>
                {(postToGroup) => (
                  <Mutation<IEditPostMutation, IEditPostMutationVariables> mutation={EditPost}>
                    {(editPost) => (
                      <Form
                        initialValues={initialValues}
                        onSubmit={async (values: any, { reset }) => {
                          // if there's id, post is being edited
                          if (initialValues._id) {
                            await editPost({
                              variables: {
                                id: initialValues._id,
                                post: convertValuesToVariables(values),
                              },
                            });
                          }

                          if (government) {
                            await postToGovernment({
                              variables: {
                                government: government._id,
                                post: convertValuesToVariables(values),
                              },
                            });
                          } else if (vendor) {
                            await postToVendor({
                              variables: {
                                vendor: vendor._id,
                                post: convertValuesToVariables(values),
                              },
                            });
                          } else if (group) {
                            await postToGroup({
                              variables: {
                                group: group._id,
                                post: convertValuesToVariables(values),
                              },
                            });
                          }

                          setIsFocused(false);
                          setTimeout(reset, 1);

                          return null;
                        }}
                        validate={validate(validationSchema)}
                      >
                        {({ handleSubmit, form, values, invalid }) => {
                          const showIconOptions =
                            values.images.length === 0 &&
                            values.documents.length === 0 &&
                            !isAddingLink &&
                            values.links.length === 0;
                          const showPostButton = isFocused;

                          return (
                            <OutsideClickHandler
                              onOutsideClick={() => {
                                const filestackIsOpen = document.getElementById("__filestack-picker");

                                if (!filestackIsOpen) {
                                  setIsFocused(false);
                                }
                              }}
                            >
                              <form
                                ref={formRef}
                                onSubmit={handleSubmit}
                                css={{
                                  padding: 16,
                                  background: "#fff",
                                  borderRadius: 4,
                                }}
                              >
                                <div
                                  css={{
                                    borderBottom: `1px solid ${palette.lightestGray}`,
                                    paddingBottom: 12,
                                  }}
                                >
                                  <div
                                    css={{
                                      display: "flex",
                                    }}
                                  >
                                    <ProfilePicture image={user.avatar} name={user.fullName} size={45} />
                                    <Field name="body">
                                      {({ input, meta }) => (
                                        <div
                                          css={{
                                            width: "100%",
                                          }}
                                        >
                                          <TextareaAutosize
                                            {...omit(["onFocus"], input)}
                                            onKeyDown={(e) => {
                                              if (e.key === "Escape") {
                                                setIsFocused(false);
                                                (e.target as HTMLTextAreaElement).blur();
                                              }
                                            }}
                                            minRows={isFocused ? 4 : 2}
                                            onFocus={() => setIsFocused(true)}
                                            placeholder={intl.formatMessage(
                                              {
                                                defaultMessage: 'What would you like to share, {firstName}?', id: 'DnymrA',
                                              },
                                              {
                                                firstName: user.firstName,
                                              },
                                            )}
                                            css={{
                                              width: "100%",
                                              padding: 10,
                                              overflow: "hidden",
                                              color: palette.darkGray,
                                              fontSize: 15,
                                              border: 0,
                                              borderRadius: 4,
                                              outline: 0,
                                              transition: "height 0.3s",
                                              resize: "none",
                                              "&::placeholder": {
                                                color: palette.darkGray,
                                                fontSize: 16,
                                                userSelect: "none",
                                              },
                                            }}
                                          />

                                          <div
                                            css={{
                                              marginLeft: 10,
                                            }}
                                          >
                                            <FieldValidationError
                                              meta={{
                                                ...meta,
                                                touched: isFocused ? meta.touched : false,
                                              }}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    </Field>
                                  </div>
                                </div>

                                {values.images.map((image: any) => (
                                  <div
                                    key={image.url}
                                    css={{
                                      marginTop: 12,
                                      position: "relative",
                                    }}
                                  >
                                    <img
                                      src={image.url}
                                      css={{
                                        maxHeight: 250,
                                        maxWidth: "100%",
                                        objectFit: "contain",
                                        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.39)",
                                      }}
                                    />

                                    <RemoveButton
                                      onClick={() => form.change("images", [])}
                                      css={{
                                        position: "absolute",
                                        top: 10,
                                        left: 10,
                                      }}
                                    />
                                  </div>
                                ))}

                                {values.links && Array.isArray(values.links) && values.links.length > 0 && (
                                  <div
                                    css={{
                                      marginTop: 15,
                                    }}
                                  >
                                    {values.links.map(({ url, ...meta }: any) => {
                                      if (!meta) {
                                        return null;
                                      }

                                      return (
                                        <div key={url || ""}>
                                          <LinkCard
                                            url={url || ""}
                                            title={meta.title || ""}
                                            siteName={meta.siteName || ""}
                                            description={meta.description || ""}
                                            favicon={meta.favicon || ""}
                                            onRemove={() => {
                                              form.change("links", []);
                                            }}
                                          />
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}

                                {values.documents.map((document: any) => {
                                  const { filename, mimeType, sizeInBytes, url } = document;

                                  return (
                                    <Margin mt={12} key={url}>
                                      <DocumentCard
                                        url={url}
                                        filename={filename}
                                        mimeType={mimeType}
                                        sizeInBytes={sizeInBytes}
                                        onRemove={() => form.change("documents", [])}
                                      />
                                    </Margin>
                                  );
                                })}

                                {isAddingLink && (
                                  <Margin mt={12}>
                                    <LinkField onCancel={() => setIsAddingLink(false)} />
                                  </Margin>
                                )}

                                {(showIconOptions || showPostButton) && (
                                  <Margin mt={12} css={{ display: "flex", alignItems: "center" }}>
                                    {showIconOptions && (
                                      <div
                                        css={{
                                          display: "grid",
                                          gridTemplateColumns: "repeat(3, 1fr)",
                                          gridColumnGap: 12,
                                          maxWidth: 121,
                                        }}
                                      >
                                        <ToolbarItem
                                          tooltip={intl.formatMessage({ defaultMessage: 'Link', id: 'JBWS0c' })}
                                          onClick={() => {
                                            setIsFocused(true);
                                            setIsAddingLink(true);
                                          }}
                                        >
                                          <LinksToolbarIcon />
                                        </ToolbarItem>

                                        <Field name="images">
                                          {({ input }) => (
                                            <Filestack
                                              options={{
                                                accept: ["image/jpeg", "image/jpg", "image/png", "image/gif"],
                                              }}
                                              onSuccess={({ filesUploaded }: any) => {
                                                if (filesUploaded.length > 0) {
                                                  const file = filesUploaded[0];

                                                  input.onChange([
                                                    {
                                                      filename: file.filename,
                                                      mimeType: file.mimetype,
                                                      sizeInBytes: file.size,
                                                      url: file.url,
                                                    },
                                                  ]);

                                                  form.change("documents", []);
                                                  form.change("links", []);
                                                }
                                              }}
                                              customRender={({ onPick }: any) => (
                                                <ToolbarItem
                                                  tooltip={intl.formatMessage({ defaultMessage: 'Image', id: '+0zv6g' })}
                                                  onClick={onPick}
                                                >
                                                  <ImageToolbarIcon />
                                                </ToolbarItem>
                                              )}
                                            />
                                          )}
                                        </Field>

                                        <Field name="documents">
                                          {({ input }) => (
                                            <Filestack
                                              options={{
                                                accept: [
                                                  "application/msword",
                                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                                  "application/vnd.ms-excel",
                                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                                  "application/pdf",
                                                ],
                                              }}
                                              onSuccess={({ filesUploaded }: any) => {
                                                if (filesUploaded.length > 0) {
                                                  const file = filesUploaded[0];

                                                  input.onChange([
                                                    {
                                                      filename: file.filename,
                                                      mimeType: file.mimetype,
                                                      sizeInBytes: file.size,
                                                      url: file.url,
                                                    },
                                                  ]);

                                                  form.change("links", []);
                                                  form.change("images", []);
                                                }
                                              }}
                                              customRender={({ onPick }: any) => (
                                                <ToolbarItem
                                                  tooltip={intl.formatMessage({
                                                    defaultMessage: 'Document', id: 'wmirkP',
                                                  })}
                                                  onClick={onPick}
                                                >
                                                  <DocumentToolbarIcon />
                                                </ToolbarItem>
                                              )}
                                            />
                                          )}
                                        </Field>
                                      </div>
                                    )}

                                    {showPostButton && (
                                      <div
                                        css={{
                                          display: "inline-flex",
                                          marginLeft: "auto",
                                        }}
                                      >
                                        <Button
                                          type="submit"
                                          size="xsmall"
                                          color={palette.innovatorBlue}
                                          disabled={invalid}
                                          css={{
                                            ":disabled": {
                                              opacity: 0.6,
                                            },
                                          }}
                                        >
                                          <FormattedMessage defaultMessage="Post" id="eKv7yX" />
                                        </Button>
                                      </div>
                                    )}
                                  </Margin>
                                )}
                              </form>
                            </OutsideClickHandler>
                          );
                        }}
                      </Form>
                    )}
                  </Mutation>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Mutation>

      {isFocused &&
        createPortal(
          <div
            css={{
              position: "fixed",
              top: 0,
              bottom: isFocused ? "0%" : "100%",
              left: 0,
              right: 0,
              background: "#000",
              transition: isFocused ? "opacity .3s" : "opacity .3s, bottom 0s .3s",
              opacity: isFocused ? 0.6 : 0,
              zIndex: 300,
            }}
          />,
          document.body,
        )}
    </>
  );
}

const ToolbarItem = ({ active = false, tooltip, ...props }: IToolbarItem): ReactElement => {
  return (
    <Hover>
      {({ hovered, bind }) => (
        <Popover
          isOpen={hovered}
          placement="top"
          backgroundColor={palette.innovatorBlue}
          render={() => {
            return (
              <div
                css={{
                  padding: 8,
                  color: palette.white,
                  fontSize: 12,
                  fontWeight: "bold",
                }}
              >
                {tooltip}
              </div>
            );
          }}
          borderColor="transparent"
          boxStyle={{
            boxShadow: "0 2px 12px 0 rgba(0, 0, 0, 0.2)",
            zIndex: 9999,
          }}
        >
          {({ innerRef }: any) => {
            return (
              <div
                {...props}
                {...bind}
                ref={innerRef}
                css={{
                  height: 35,
                  width: 35,
                  borderRadius: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: active ? "#b9e2fd" : palette.lightestBlue,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: active ? "#b9e2fd" : palette.lightBlue,
                  },
                }}
              />
            );
          }}
        </Popover>
      )}
    </Hover>
  );
};
