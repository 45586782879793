import { Box, CountryFlag, TCountryCode } from "@govlaunch/web";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import useIsMobile from "~/lib/hooks/useIsMobile";
import ToggleFollow from "~/lib/mutations/ToggleFollow";
import IntlRelativeTime from "~/src/shared/components/IntlRelativeTime";
import * as Types from "~/types/types";
import { useSelfie } from "~/components/auth/Auth";
import FeedAuthorPicture from "~/components/feed/card/FeedAuthorPicture";
import FeedCard from "~/components/feed/card/FeedCard";
import { FollowButton } from "~/components/feed/FollowButton";

type User = Pick<
  Types.IGovernmentUser,
  "__typename" | "_id" | "isSelfie" | "slug" | "avatar" | "fullName" | "isFollowing"
> & {
  government: Types.Maybe<
    Pick<Types.IGovernment, "_id" | "slug" | "logo" | "name" | "isFollowing"> & {
      city: Pick<Types.ICity, "country" | "countryName">;
    }
  >;
};

interface IUserJoinedGovernmentFeed {
  user: User;
  createdAt: any;
}

const UserJoinedGovernmentFeed: React.FunctionComponent<IUserJoinedGovernmentFeed> = ({ user, createdAt }) => {
  const loggedInUser = useSelfie();
  const index = Math.round(Math.random() * 1);
  const background = ["/static/feed/blue-background.png", "/static/feed/purple-background.png"][index];
  const buttonColor = ["#166e6a", "#5a3b8d"][index];
  const { government } = user;
  const isMobile = useIsMobile();

  if (!government || !loggedInUser) {
    return null;
  }

  const followButton = (
    <ToggleFollow targetId={user._id} entityType={user.__typename} viewerIsFollowing={user.isFollowing || false}>
      {({ toggle }) => (
        <FollowButton
          isFollowing={user.isFollowing || false}
          onFollow={toggle}
          onUnfollow={toggle}
          color={buttonColor}
        />
      )}
    </ToggleFollow>
  );

  return (
    <FeedCard
      css={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FeedAuthorPicture author={user as Types.IGovernmentUser} government={government} />

        <div
          css={{
            marginLeft: 12,
          }}
        >
          <p
            css={{
              color: "#fff",
              fontSize: 15,
            }}
          >
            <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${user.slug}`}>
              <a
                css={{
                  color: "#fff",
                  fontWeight: 600,
                  ":hover": {
                    textDecoration: "underline !important",
                  },
                }}
              >
                {user.fullName}
              </a>
            </Link>

            <span>
              {" "}
              <FormattedMessage defaultMessage="joined" id="WNcxGO" />{" "}
            </span>

            <Link
              href="/governments/[governmentSlug]/[[...content]]"
              as={`/governments/${government.slug}`}
              passHref={true}
            >
              <a
                css={{
                  color: "#fff",
                  fontWeight: 600,
                  display: "inline-flex",
                  alignItems: "center",
                  ":hover": {
                    textDecoration: "underline !important",
                  },
                }}
              >
                {government.name}

                <Box as="span" display="inline-block" ml={2}>
                  <CountryFlag countryCode={government.city.country as TCountryCode} width={25} height={14} />
                </Box>
              </a>
            </Link>
          </p>

          <span
            css={{
              color: "#fff",
              margin: "8px 0 0",
              fontSize: 14,
            }}
          >
            <IntlRelativeTime value={createdAt} />
          </span>
        </div>

        {!isMobile && <div css={{ marginLeft: "auto" }}>{followButton}</div>}
      </div>

      {isMobile && (
        <div
          css={{
            marginTop: 12,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {followButton}
        </div>
      )}
    </FeedCard>
  );
};

export default UserJoinedGovernmentFeed;
