import React from "react";
import ContentLoader from "react-content-loader";
import * as palette from "@govlaunch/palette";

const LoadingFeedCard = () => (
  <div
    css={{
      backgroundColor: palette.white,
      borderRadius: 4,
      border: `solid 1px ${palette.lightestGray}`,
    }}
  >
    <ContentLoader height={79} width={730} speed={1} foregroundColor="#f3f3f3" backgroundColor="#ecebeb">
      <circle cx="38" cy="41" r="23" />
      <rect x="76" y="22" rx="6" ry="6" width="330" height="11" />
      <rect x="76" y="47" rx="6" ry="6" width="180" height="11" />
    </ContentLoader>
  </div>
);

export default LoadingFeedCard;
