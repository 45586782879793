import React from "react";
import * as palette from "@govlaunch/palette";
import BookmarkIcon from "~/components/icons/BookmarkIcon";

interface IBookmarkButtonIcon {
  bookmarked: boolean;
  bookmarkedColor?: string;
  bookmarkedStrokeColor?: string;
  onClick: React.MouseEventHandler;
}

const BookmarkButtonIcon: React.FunctionComponent<IBookmarkButtonIcon> = ({
  onClick,
  bookmarked,
  bookmarkedColor = palette.primary,
  bookmarkedStrokeColor = palette.primary,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      css={{
        border: 0,
        background: "none",
        padding: 0,
        display: "inline-flex",
        outline: "none",
        cursor: "pointer",
        height: 18,
      }}
    >
      <BookmarkIcon
        fill={bookmarked ? bookmarkedColor : undefined}
        stroke={bookmarked ? bookmarkedStrokeColor : undefined}
      />
    </button>
  );
};

export default BookmarkButtonIcon;
