import { gql } from "@apollo/client";
import ConnectFields from "~/lib/queries/fragments/ConnectFields";

export default gql`
  query Connect(
    $targetId: ObjectId
    $targetType: ConnectTargetType
    $limit: Int!
    $beforeThisId: ObjectId
    $excludeUser: ObjectId
  ) {
    connect(
      targetId: $targetId
      targetType: $targetType
      limit: $limit
      beforeThisId: $beforeThisId
      excludeUser: $excludeUser
    ) {
      totalCount
      items {
        __typename
        _id
        ...ConnectFields
      }
    }
  }

  ${ConnectFields}
`;
