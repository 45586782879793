import { Logo } from "@govlaunch/core";
import * as palette from "@govlaunch/palette";
import { default as Link, default as NextLink } from "next/link";
import React from "react";
import { Hover } from "react-powerplug";
import ToggleBookmark from "~/lib/mutations/ToggleBookmark";
import ToggleUpvote from "~/lib/mutations/ToggleUpvote";
import * as Types from "~/types/types";
import { useSelfie } from "~/components/auth/Auth";
import mediaQueries from "~/components/mediaQueries";
import { Margin } from "~/components/spacings";
import isAdmin from "~/components/user/utils/isAdmin";
import FeedAuthorPicture from "~/components/feed/card/FeedAuthorPicture";
import FeedCard from "~/components/feed/card/FeedCard";
import FeedCardActions from "~/components/feed/card/FeedCardActions";
import FeedCardPitch, { Time } from "~/components/feed/card/FeedCardPitch";
import { FeedContext } from "~/components/feed/interfaces";
import { FormattedMessage, useIntl } from "react-intl";

type AuthorGovernment = Types.Maybe<
  Pick<Types.IGovernment, "_id" | "slug" | "name" | "logo"> & {
    city: Pick<Types.ICity, "countryName" | "country">;
  }
>;
type AuthorCompany = Types.Maybe<Pick<Types.ICompany, "_id" | "slug" | "name" | "logo">>;

interface IStoryFeed {
  createdAt: any;
  context?: FeedContext;
  story: Pick<
    Types.IStory,
    | "_id"
    | "slug"
    | "title"
    | "introduction"
    | "verticalCover"
    | "upvotesCount"
    | "commentsCount"
    | "viewerDidUpvote"
    | "viewerDidBookmark"
    | "viewerCanRead"
    | "viewerDidComment"
    | "viewerIsAuthor"
    | "viewerIsCollaborator"
    | "createdAt"
  > & {
    author: Types.Maybe<
      | ({ __typename: "GovernmentUser" } & Pick<
          Types.IGovernmentUser,
          "_id" | "slug" | "email" | "fullName" | "avatar" | "jobTitle" | "isSiteAdmin" | "isLimitedSiteAdmin"
        > & {
            government: AuthorGovernment;
          })
      | ({ __typename: "VendorUser" } & Pick<
          Types.IVendorUser,
          "_id" | "slug" | "email" | "fullName" | "avatar" | "jobTitle" | "isSiteAdmin" | "isLimitedSiteAdmin"
        > & {
            company: AuthorCompany;
          })
      | ({ __typename: "PendingUser" } & Pick<
          Types.IPendingUser,
          "_id" | "email" | "slug" | "avatar" | "fullName" | "isSiteAdmin" | "isLimitedSiteAdmin"
        >)
    >;
  } & {
    government: Types.Maybe<
      Pick<Types.IGovernment, "_id" | "slug" | "name" | "logo"> & {
        city: Pick<Types.ICity, "countryName" | "country">;
      }
    >;
  };
}

const StoryFeed: React.FunctionComponent<IStoryFeed> = ({ story, context, createdAt }) => {
  const viewer = useSelfie();
  const intl = useIntl();
  const { author, government } = story;
  const isAdminAuthor = isAdmin(author);
  const storyLink = {
    href: `/stories/[storySlug]`,
    as: `/stories/${story.slug}`,
  };
  const editStoryLink = {
    href: `/edit-story?storySlug=${story.slug}`,
    as: `/stories/${story.slug}/edit`,
  };

  return (
    <Hover>
      {({ bind, hovered }) => (
        <FeedCard
          style={{
            opacity: story.viewerCanRead ? 1 : 0.5,
          }}
          {...bind}
        >
          {viewer && (
            <ToggleUpvote
              viewer={viewer}
              upvotesCount={story.upvotesCount || 0}
              viewerDidUpvote={story.viewerDidUpvote || false}
              targetType="STORY"
              targetId={story._id}
            >
              {({ toggle: toggleUpvote }) => (
                <ToggleBookmark
                  targetId={story._id}
                  targetType="STORY"
                  viewerDidBookmark={story.viewerDidBookmark || false}
                >
                  {({ toggle: toggleBookmark }) => (
                    <FeedCardActions
                      isShowing={hovered}
                      viewerDidUpvote={story.viewerDidUpvote || false}
                      onUpvote={toggleUpvote}
                      viewerDidBookmark={story.viewerDidBookmark || false}
                      onBookmark={toggleBookmark}
                      link={storyLink}
                      viewerCanEdit={story.viewerIsAuthor || story.viewerIsCollaborator || false}
                      editLink={editStoryLink}
                      viewerDidComment={story.viewerDidComment || false}
                    />
                  )}
                </ToggleBookmark>
              )}
            </ToggleUpvote>
          )}

          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {government ? (
              <FeedAuthorPicture
                author={author}
                government={government}
                gatedAuthor={isAdminAuthor}
                context={context}
              />
            ) : (
              <Link href="/vendor?vendorSlug=govlaunch-inc" as="/vendors/govlaunch-inc" passHref={true}>
                <a
                  css={{
                    borderRadius: "100%",
                    width: 45,
                    height: 45,
                    border: `1px solid ${palette.lightestGray}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Logo size={32} />
                </a>
              </Link>
            )}

            <Margin ml={10}>
              {government ? (
                <FeedCardPitch
                  author={author}
                  gatedAuthor={isAdminAuthor}
                  government={government}
                  action={intl.formatMessage({ id: 'uxICV0', defaultMessage: "shared a" })}
                  target={intl.formatMessage({ defaultMessage: 'story', id: 'NHDJKH' })}
                  targetLink={storyLink}
                  date={createdAt}
                />
              ) : (
                <div
                  css={mediaQueries({
                    color: palette.darkGray,
                    display: "flex",
                    flexDirection: ["column", "row"],
                    fontSize: 15,
                  })}
                >
                  <Link href="/vendor?vendorSlug=govlaunch-inc" as="/vendors/govlaunch-inc" passHref={true}>
                    <a
                      css={{
                        color: palette.primary,
                        fontWeight: "bold",
                        ":hover": {
                          textDecoration: "underline !important",
                        },
                      }}
                    >
                      Govlaunch
                    </a>
                  </Link>

                  <Margin mx={[0, 5]}>
                    <NextLink href="/stories/[storySlug]" as={`/stories/${story.slug}`} passHref={true}>
                      <a
                        css={{
                          color: palette.darkGray,
                          fontWeight: 600,
                          ":hover": {
                            textDecoration: "underline !important",
                          },
                        }}
                      >
                        <FormattedMessage defaultMessage="shared a story" id="zl2cG8" />
                      </a>
                    </NextLink>
                  </Margin>
                  <Time
                    date={createdAt}
                    targetLink={{
                      href: `/vendor?vendorSlug=govlaunch-inc`,
                      as: `/vendors/govlaunch-inc`,
                    }}
                  />
                </div>
              )}

              <Margin mt={9}>
                <div
                  css={{
                    fontSize: 15,
                    display: "flex",
                  }}
                >
                  <Link {...storyLink} passHref={true}>
                    <a
                      css={{
                        color: palette.darkGray,
                        fontWeight: "bold",
                        wordBreak: "break-word",
                        ":hover": {
                          textDecoration: "underline !important",
                        },
                      }}
                    >
                      {story.title}
                    </a>
                  </Link>

                  {!isAdminAuthor && government && (
                    <Margin mx={5}>
                      <span
                        css={{
                          color: palette.darkGray,
                        }}
                      >
                        <FormattedMessage
                          defaultMessage="in <location></location>" id="atBiSP"
                          values={{
                            // eslint-disable-next-line react/display-name
                            location: () => {
                              return (
                                <Link
                                  href={`/government?governmentSlug=${government.slug}`}
                                  as={`/governments/${government.slug}`}
                                  passHref={true}
                                >
                                  <a
                                    css={{
                                      color: palette.mediumGray,
                                      ":hover": {
                                        textDecoration: "underline !important",
                                      },
                                    }}
                                  >
                                    {government.name}
                                  </a>
                                </Link>
                              );
                            },
                          }}
                        />
                      </span>
                    </Margin>
                  )}
                </div>
              </Margin>
            </Margin>
          </div>
        </FeedCard>
      )}
    </Hover>
  );
};

export default StoryFeed;
