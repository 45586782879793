import React from "react";
import { Hover } from "react-powerplug";
import { Button } from "@govlaunch/core";
import { FormattedMessage } from "react-intl";

export interface IFollowButton {
  isFollowing: boolean;
  color: string;
  onFollow: () => any;
  onUnfollow: () => any;
}

export const FollowButton: React.FunctionComponent<IFollowButton> = ({ isFollowing, onFollow, color, onUnfollow }) => {
  return (
    <Hover>
      {({ hovered, bind }) => {
        if (!isFollowing) {
          return (
            <Button
              theme="secondary"
              color={color}
              size="xsmall"
              onClick={onFollow}
              {...bind}
              css={{
                border: 0,
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
                background: "#fff",
                minWidth: 135,
                textTransform: "uppercase",
                fontWeight: 600,
              }}
            >
              <FormattedMessage defaultMessage="Follow" id="ieGrWo" />
            </Button>
          );
        }

        if (!hovered) {
          return (
            <Button
              theme="secondary"
              color={color}
              size="xsmall"
              onClick={onUnfollow}
              {...bind}
              css={{
                border: 0,
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
                background: "#fff",
                minWidth: 135,
                textTransform: "uppercase",
                fontWeight: 600,
              }}
            >
              <FormattedMessage defaultMessage="Following" id="cPIKU2" />
            </Button>
          );
        }

        return (
          <Button
            theme="secondary"
            color={color}
            size="xsmall"
            onClick={onUnfollow}
            {...bind}
            css={{
              border: 0,
              boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
              background: "#fff",
              minWidth: 135,
              textTransform: "uppercase",
              fontWeight: 600,
            }}
          >
            <FormattedMessage defaultMessage="Unfollow" id="izWS4J" />
          </Button>
        );
      }}
    </Hover>
  );
};
