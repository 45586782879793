import { gql } from "@apollo/client";
import GroupProjectFields from "~/lib/queries/fragments/GroupProjectFields";
import GovernmentProjectFields from "~/lib/queries/fragments/GovernmentProjectFields";
import VendorProjectFields from "~/lib/queries/fragments/VendorProjectFields";

export default gql`
  fragment ConnectFields on ConnectItem {
    _id
    createdAt
    target {
      __typename
      ... on GovernmentProject {
        _id
        ...GovernmentProjectFields
      }
      ... on GroupProject {
        _id
        ...GroupProjectFields
      }
      ... on VendorProject {
        _id
        ...VendorProjectFields
      }

      ... on Government {
        _id
        name
        slug
        logo
      }

      ... on Company {
        _id
        name
        slug
        logo
      }

      ... on Story {
        _id
        slug
        title
        introduction
        viewerCanRead
        viewerDidUpvote
        viewerDidBookmark
        viewerDidComment
        viewerIsAuthor
        viewerIsCollaborator
        commentsCount
        upvotesCount
        verticalCover
        updatedAt
        author {
          ... on GovernmentUser {
            _id
            slug
            email
            fullName
            avatar
            jobTitle
            isSelfie
            isSiteAdmin
            isLimitedSiteAdmin
            government {
              _id
              slug
              name
              logo
              city {
                _id
                country
                countryName
              }
            }
          }
          ... on VendorUser {
            _id
            slug
            email
            isSelfie
            isSiteAdmin
            isLimitedSiteAdmin
            fullName
            avatar
            jobTitle
            company {
              _id
              slug
              name
              logo
            }
          }
          ... on PendingUser {
            _id
            email
            slug
            avatar
            fullName
            isSiteAdmin
            isLimitedSiteAdmin
          }
        }
        storyGovernment: government {
          _id
          name
          slug
          logo
          isInnovator
          state
          city {
            _id
            country
            countryName
          }
        }
        createdAt
      }
      ... on GovernmentUser {
        _id
        isSelfie
        isSiteAdmin
        isLimitedSiteAdmin
        slug
        avatar
        fullName
        isFollowing
        government {
          _id
          slug
          logo
          name
          isFollowing
          city {
            _id
            country
            countryName
          }
        }
      }
      ... on VendorUser {
        _id
        isSelfie
        isSiteAdmin
        isLimitedSiteAdmin
        slug
        avatar
        fullName
        isFollowing
      }
      ... on PendingUser {
        _id
        isSelfie
        isSiteAdmin
        isLimitedSiteAdmin
        slug
        avatar
        fullName
        isFollowing
      }
      ... on GovernmentPost {
        _id
        body
        viewerCanEdit
        created: createdAt
        featuredAt
        viewerCanDelete
        viewerIsAuthor
        postAudience: audience
        upvotesCount
        commentsCount
        viewerDidBookmark
        viewerDidUpvote
        viewerDidComment

        postAuthor: author {
          ... on GovernmentUser {
            _id
            slug
            fullName
            firstName
            avatar
            jobTitle
            email
            government {
              _id
              name
              logo
              slug
              city {
                _id
                country
                countryName
              }
            }
          }
          ... on VendorUser {
            _id
            slug
            fullName
            firstName
            avatar
            email
            jobTitle
            company {
              _id
              name
              logo
              slug
            }
          }
          ... on PendingUser {
            _id
            slug
            email
            fullName
            firstName
            avatar
            jobTitle
          }
        }
        postAttachments: attachments {
          mimeType
          filename
          sizeInBytes
          url
        }
        postLinks: links {
          addedAt
          description
          title
          favicon
          siteName
          url
        }
        postGovernment: government {
          _id
          slug
          name
          logo
          city {
            _id
            country
            countryName
          }
        }
      }

      ... on VendorPost {
        _id
        body
        viewerCanEdit
        created: createdAt
        featuredAt
        viewerCanDelete
        viewerIsAuthor
        postAudience: audience
        upvotesCount
        commentsCount
        viewerDidBookmark
        viewerDidUpvote
        viewerDidComment

        postAuthor: author {
          ... on GovernmentUser {
            _id
            slug
            fullName
            firstName
            avatar
            jobTitle
            email
            government {
              _id
              name
              logo
              slug
              city {
                _id
                country
                countryName
              }
            }
          }
          ... on VendorUser {
            _id
            slug
            fullName
            firstName
            avatar
            email
            jobTitle
            company {
              _id
              name
              logo
              slug
            }
          }
          ... on PendingUser {
            _id
            slug
            email
            fullName
            firstName
            avatar
            jobTitle
          }
        }
        postAttachments: attachments {
          mimeType
          filename
          sizeInBytes
          url
        }
        postLinks: links {
          addedAt
          description
          title
          favicon
          siteName
          url
        }
        postVendor: vendor {
          _id
          slug
          name
          logo
        }
      }

      ... on GroupPost {
        _id
        body
        viewerCanEdit
        viewerCanDelete
        created: createdAt
        featuredAt
        upvotesCount
        commentsCount
        viewerDidBookmark
        viewerDidUpvote
        viewerDidComment
        viewerCanEdit
        viewerIsAuthor
        postAudience: audience
        postGroup: group {
          _id
          name
          slug
          logo
        }
        postAuthor: author {
          ... on GovernmentUser {
            _id
            slug
            fullName
            firstName
            avatar
            email
            jobTitle
            government {
              _id
              name
              logo
              slug
              city {
                _id
                country
                countryName
              }
            }
          }
          ... on VendorUser {
            _id
            slug
            fullName
            firstName
            avatar
            jobTitle
            email
            company {
              _id
              name
              logo
              slug
            }
          }
          ... on PendingUser {
            _id
            slug
            email
            fullName
            firstName
            avatar
            jobTitle
          }
        }
        postAttachments: attachments {
          mimeType
          filename
          sizeInBytes
          url
        }
        postLinks: links {
          addedAt
          description
          title
          favicon
          siteName
          url
        }
      }
    }
  }

  ${GroupProjectFields}
  ${GovernmentProjectFields}
  ${VendorProjectFields}
`;
