import React, { HTMLAttributes } from "react";
import ProjectIcon from "~/components/icons/ProjectIcon";
import * as palette from "@govlaunch/palette";
import { Button } from "@govlaunch/core";
import useIsMobile from "~/lib/hooks/useIsMobile";
import Link from "next/link";
import { useIntl } from "react-intl";

interface INoProjectsBoxProps {
  onAddProject: (arg: any) => any;
  dataIntercomTarget?: string | null;
  addButtonIntercomTarget?: string | null;
  title?: string | null;
  text?: string | null;
  buttonText?: string | null;
  groupId?: any;
}

export default function NoProjectsBox({
  onAddProject,
  dataIntercomTarget,
  addButtonIntercomTarget,
  title,
  text,
  buttonText,
  groupId,
  ...props
}: INoProjectsBoxProps & Omit<HTMLAttributes<HTMLDivElement>, "title">) {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const titleOrIntl = title || intl.formatMessage({ defaultMessage: 'Share your projects', id: 'k0Ak8L' });
  const textOrIntl =
    text ||
    intl.formatMessage({
      defaultMessage: 'Share what you’re working on with your peers – or even your community. Adding a project takes less than a minute!', id: 'iLbFZD',
    });
  const buttonTextOrIntl = buttonText || intl.formatMessage({ defaultMessage: 'Add Project', id: 'BAZtPp' });

  if (isMobile) {
    return (
      <NoProjectBoxMobile
        dataIntercomTarget={dataIntercomTarget}
        addButtonIntercomTarget={addButtonIntercomTarget}
        title={titleOrIntl}
        text={textOrIntl}
        buttonText={buttonTextOrIntl}
        groupId={groupId}
        {...props}
      />
    );
  }

  return (
    <NoProjectBoxContent
      onAddProject={onAddProject}
      dataIntercomTarget={dataIntercomTarget}
      addButtonIntercomTarget={addButtonIntercomTarget}
      title={titleOrIntl}
      text={textOrIntl}
      buttonText={buttonTextOrIntl}
      groupId={groupId}
      {...props}
    />
  );
}

interface INoProjectsBoxContentProps {
  onAddProject?: (arg: any) => any;
  dataIntercomTarget?: string | null;
  addButtonIntercomTarget?: string | null;
  title?: string | null;
  text?: string | null;
  buttonText?: string | null;
  groupId?: any;
}

function NoProjectBoxContent({
  onAddProject,
  dataIntercomTarget,
  addButtonIntercomTarget,
  title,
  text,
  buttonText,
  ...props
}: INoProjectsBoxContentProps) {
  return (
    <div
      css={{
        borderRadius: 4,
        background: "#fff",
        border: `1px solid ${palette.lightestGray}`,
        padding: 24,
      }}
      data-intercom-target={dataIntercomTarget}
      {...props}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          maxWidth: 400,
          margin: "0 auto",
        }}
      >
        <ProjectIcon width={60} height={60} />

        <p
          css={{
            margin: "12px 0 0 0",
            color: palette.text,
            fontWeight: 500,
            fontSize: 16,
          }}
        >
          {title}
        </p>

        <p
          css={{
            margin: "12px 0 0 0",
            color: palette.darkGray,
            fontSize: 14,
          }}
        >
          {text}
        </p>

        <Button
          data-intercom-target={addButtonIntercomTarget}
          size="small"
          color={palette.innovatorBlue}
          theme="secondary"
          onClick={onAddProject}
          css={{
            margin: "12px 0 0 0",
            minWidth: 150,
          }}
        >
          {buttonText}
        </Button>
      </div>
    </div>
  );
}

function NoProjectBoxMobile({
  dataIntercomTarget,
  addButtonIntercomTarget,
  title,
  text,
  buttonText,
  groupId,
  ...props
}: INoProjectsBoxContentProps) {
  return (
    <Link href={`/projects/create?groupId=${groupId}`} as="/projects/create" passHref={true}>
      <NoProjectBoxContent
        dataIntercomTarget={dataIntercomTarget}
        addButtonIntercomTarget={addButtonIntercomTarget}
        title={title}
        text={text}
        buttonText={buttonText}
        {...props}
      />
    </Link>
  );
}
