import ProfilePicture from "@govlaunch/profile-picture";
import Link from "next/link";
import React from "react";
import { FormattedMessage } from "react-intl";
import ToggleFollow from "~/lib/mutations/ToggleFollow";
import IntlRelativeTime from "~/src/shared/components/IntlRelativeTime";
import * as Types from "~/types/types";
import FeedCard from "~/components/feed/card/FeedCard";
import { FollowButton } from "~/components/feed/FollowButton";

type User =
  | Pick<Types.IGovernmentUser, "__typename" | "_id" | "isSelfie" | "slug" | "avatar" | "fullName" | "isFollowing">
  | Pick<Types.IVendorUser, "__typename" | "_id" | "isSelfie" | "slug" | "avatar" | "fullName" | "isFollowing">
  | Pick<Types.IPendingUser, "__typename" | "_id" | "isSelfie" | "slug" | "avatar" | "fullName" | "isFollowing">;

interface IUserJoinedGovernmentFeed {
  user: User;
  createdAt: any;
}

const UserJoinedGroupFeedItem = ({ user, createdAt }: IUserJoinedGovernmentFeed) => {
  const index = Math.round(Math.random() * 1);
  const background = ["/static/feed/blue-background.png", "/static/feed/purple-background.png"][index];
  const buttonColor = ["#166e6a", "#5a3b8d"][index];

  return (
    <FeedCard
      css={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${user.slug}`}>
          <a
            css={{
              display: "flex",
            }}
          >
            <ProfilePicture size={45} image={user.avatar} name={user.fullName} />
          </a>
        </Link>

        <div
          css={{
            marginLeft: 12,
          }}
        >
          <p
            css={{
              color: "#fff",
              fontSize: 15,
            }}
          >
            <Link passHref={true} href="/[userSlug]/[[...tab]]" as={`/@${user.slug}`}>
              <a
                css={{
                  color: "#fff",
                  fontWeight: 600,
                  ":hover": {
                    textDecoration: "underline !important",
                  },
                }}
              >
                {user.fullName}
              </a>
            </Link>

            <span>
              {" "}
              <FormattedMessage defaultMessage="joined this group" id="bHJLnv" />{" "}
            </span>
          </p>

          <p
            css={{
              color: "#fff",
              margin: "8px 0 0",
              fontSize: 14,
            }}
          >
            <IntlRelativeTime value={createdAt} />
          </p>
        </div>

        <div
          css={{
            marginLeft: "auto",
          }}
        >
          <ToggleFollow targetId={user._id} entityType={user.__typename} viewerIsFollowing={user.isFollowing || false}>
            {({ toggle }) => (
              <FollowButton
                isFollowing={user.isFollowing || false}
                onFollow={toggle}
                onUnfollow={toggle}
                color={buttonColor}
              />
            )}
          </ToggleFollow>
        </div>
      </div>
    </FeedCard>
  );
};

export default UserJoinedGroupFeedItem;
