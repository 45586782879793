import * as palette from "@govlaunch/palette";
import Link, { LinkProps } from "next/link";
import React from "react";
import { Hover } from "react-powerplug";
import ToggleBookmark from "~/lib/mutations/ToggleBookmark";
import ToggleUpvote from "~/lib/mutations/ToggleUpvote";
import { IGovernmentProjectFieldsFragment } from "~/lib/queries/fragments/__generated__/GovernmentProjectFields.generated";
import { IGroupProjectFieldsFragment } from "~/lib/queries/fragments/__generated__/GroupProjectFields.generated";
import { IVendorProjectFieldsFragment } from "~/lib/queries/fragments/__generated__/VendorProjectFields.generated";
import { useSelfie } from "~/components/auth/Auth";
import { Margin } from "~/components/spacings";
import isAdmin from "~/components/user/utils/isAdmin";
import FeedAuthorPicture from "~/components/feed/card/FeedAuthorPicture";
import FeedCard from "~/components/feed/card/FeedCard";
import FeedCardActions from "~/components/feed/card/FeedCardActions";
import FeedCardPitch from "~/components/feed/card/FeedCardPitch";
import { FeedContext } from "~/components/feed/interfaces";
import { Box, CountryFlag, TCountryCode, Tooltip } from "@govlaunch/web";
import { FormattedMessage, useIntl } from "react-intl";

interface IProjectFeed {
  project: IGroupProjectFieldsFragment | IGovernmentProjectFieldsFragment | IVendorProjectFieldsFragment;
  createdAt: any;
  context?: FeedContext;
}

const ProjectFeed: React.FunctionComponent<IProjectFeed> = ({ project, context, createdAt }) => {
  const viewer = useSelfie();
  const { author } = project;
  const projectLink = getProjectLink(project);
  const isAdminAuthor = isAdmin(author);
  const intl = useIntl();

  return (
    <Hover>
      {({ hovered, bind }) => (
        <FeedCard
          style={{
            opacity: project.viewerCanRead ? 1 : 0.5,
          }}
          {...bind}
        >
          {viewer && (
            <ToggleUpvote
              viewer={viewer}
              upvotesCount={project.upvotesCount || 0}
              viewerDidUpvote={project.viewerDidUpvote || false}
              targetType="PROJECT"
              targetId={project._id}
            >
              {({ toggle: toggleUpvote }) => (
                <ToggleBookmark
                  targetId={project._id}
                  targetType="PROJECT"
                  viewerDidBookmark={project.viewerDidBookmark || false}
                >
                  {({ toggle: toggleBookmark }) => (
                    <FeedCardActions
                      isShowing={hovered}
                      viewerDidUpvote={project.viewerDidUpvote || false}
                      onUpvote={toggleUpvote}
                      viewerDidBookmark={project.viewerDidBookmark || false}
                      onBookmark={toggleBookmark}
                      link={projectLink}
                      viewerCanEdit={project.viewerCanEdit || false}
                      editLink={getEditProjectLink(project)}
                      viewerDidComment={project.viewerDidComment || false}
                    />
                  )}
                </ToggleBookmark>
              )}
            </ToggleUpvote>
          )}

          <div
            css={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <FeedAuthorPicture
              author={author}
              vendor={project.__typename === "VendorProject" ? project.vendor : null}
              government={project.__typename === "GovernmentProject" ? project.government : null}
              group={project.__typename === "GroupProject" ? project.group : null}
              gatedAuthor={isAdminAuthor}
              context={context}
            />

            <Margin ml={10}>
              <FeedCardPitch
                author={author}
                gatedAuthor={isAdminAuthor}
                government={project.__typename === "GovernmentProject" ? project.government : null}
                vendor={project.__typename === "VendorProject" ? project.vendor : null}
                action={intl.formatMessage({ id: 'uxICV0', defaultMessage: "shared a" })}
                target={intl.formatMessage({ defaultMessage: 'project', id: 'RbFAl7' })}
                targetLink={projectLink}
                date={createdAt}
              />

              <Margin mt={9}>
                <div
                  css={{
                    fontSize: 15,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Link {...projectLink} passHref={true}>
                    <a
                      css={{
                        color: palette.darkGray,
                        fontWeight: "bold",
                        wordBreak: "break-word",
                        ":hover": {
                          textDecoration: "underline !important",
                        },
                      }}
                    >
                      {project.title}
                    </a>
                  </Link>

                  {!isAdminAuthor && project.__typename === "GovernmentProject" && (
                    <div
                      css={{
                        marginTop: 4,
                      }}
                    >
                      <FormattedMessage
                        defaultMessage="in <location></location>" id="atBiSP"
                        values={{
                          // eslint-disable-next-line react/display-name
                          location: () => {
                            return (
                              <Link
                                href={`/government?governmentSlug=${project.government.slug}`}
                                as={`/governments/${project.government.slug}`}
                                passHref={true}
                              >
                                <a
                                  css={{
                                    color: palette.mediumGray,
                                    display: "inline-flex",
                                    alignItems: "center",
                                    ":hover": {
                                      textDecoration: "underline !important",
                                    },
                                  }}
                                >
                                  {project.government.name}

                                  <Tooltip
                                    maxWidth="230px"
                                    textAlign="center"
                                    aria-label={project.government.name}
                                    label={project.government.city.countryName}
                                    bg="blue.600"
                                    py={2}
                                  >
                                    <Box as="span" display="inline-block" ml={2}>
                                      <CountryFlag
                                        countryCode={project.government.city.country as TCountryCode}
                                        width={25}
                                        height={14}
                                      />
                                    </Box>
                                  </Tooltip>
                                </a>
                              </Link>
                            );
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              </Margin>
            </Margin>
          </div>
        </FeedCard>
      )}
    </Hover>
  );
};

function getProjectLink(
  project: IGroupProjectFieldsFragment | IGovernmentProjectFieldsFragment | IVendorProjectFieldsFragment,
): LinkProps {
  return {
    href: "/projects/[projectSlug]",
    as: `/projects/${project.slug}`,
  };
}

function getEditProjectLink(
  project: IGroupProjectFieldsFragment | IGovernmentProjectFieldsFragment | IVendorProjectFieldsFragment,
): LinkProps {
  return {
    href: "/projects/[projectSlug]/edit",
    as: `/projects/${project.slug}/edit`,
  };
}

export default ProjectFeed;
