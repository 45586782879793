import * as palette from "@govlaunch/palette";
import Link, { LinkProps } from "next/link";
import React, { HTMLAttributes } from "react";
import mediaQueries from "~/components/mediaQueries";
import CountBadge from "~/components/profile/CountBadge";

interface INewLinkTabProps {
  title: string;
  active?: boolean;
  count?: number | null;
  dataIntercomTarget?: string | null;
}

export default function NewLinkTab({
  href,
  as,
  title,
  active,
  count,
  dataIntercomTarget,
  ...props
}: INewLinkTabProps & LinkProps & HTMLAttributes<HTMLAnchorElement>) {
  return (
    <Link href={href} as={as} passHref={true}>
      <a
        data-intercom-target={dataIntercomTarget}
        css={mediaQueries({
          padding: [0, 0, 10, 15],
          minHeight: [0, 0, 55, 65],
          marginLeft: [!!count && count > 0 ? 30 : 0, 0, 0, 0],
          display: "flex",
          alignItems: "center",
          color: active ? palette.innovatorBlue : palette.darkGray,
          fontWeight: active ? 600 : "normal",
          fontSize: 16,
        })}
        {...props}
      >
        {title} {!!count && count > 0 && <CountBadge>{count}</CountBadge>}
      </a>
    </Link>
  );
}
