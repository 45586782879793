import React from "react";
import ListIcon from "~/components/icons/ListIcon";
import mediaQueries from "~/components/mediaQueries";
import * as palette from "@govlaunch/palette";
import { FeedContext } from "~/components/feed/interfaces";
import useIsMobile from "~/lib/hooks/useIsMobile";
import Link from "next/link";
import { FormattedMessage } from "react-intl";

interface IAddProjectContentBoxProps {
  onClick: () => any;
  context?: FeedContext | null;
  groupId?: any;
}

export default function AddProjectContentBox({ onClick, context, groupId }: IAddProjectContentBoxProps) {
  const isMobile = useIsMobile();

  if (isMobile) {
    return <AddProjectContentBoxMobile context={context} groupId={groupId} />;
  }

  return <AddProjectButton onClick={onClick} context={context} />;
}

interface IAddProjectContentBoxMobileProps {
  context?: FeedContext | null;
  groupId?: any;
}

function AddProjectContentBoxMobile({ context, groupId }: IAddProjectContentBoxMobileProps) {
  return (
    <Link href={`/projects/create?groupId=${groupId}`} as="/projects/create" passHref={true}>
      <AddProjectButton context={context} />
    </Link>
  );
}

interface IAddProjectButtonProps {
  onClick?: () => any;
  context?: FeedContext | null;
}

function AddProjectButton({ onClick, context }: IAddProjectButtonProps) {
  return (
    <span
      onClick={onClick}
      role="button"
      css={mediaQueries({
        color: palette.darkGray,
        fontWeight: 500,
        width: "100%",
        background: "#fff",
        cursor: "pointer",
        border: `1px solid transparent`,
        borderRight: context === "user" ? 0 : `1px solid ${palette.lightestGray}`,
        height: 48,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: [11, 11, 12, 12],
        textTransform: "uppercase",

        ":hover": {
          border: `1px solid #9ac4e1`,
        },
      })}
    >
      <ListIcon />

      <span
        css={{
          marginLeft: 4,
        }}
      >
        <FormattedMessage defaultMessage="Add Project" id="BAZtPp" />
      </span>
    </span>
  );
}
