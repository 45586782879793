import React, { ReactElement } from "react";
import {
  IPost,
  IVendorUser,
  IPendingUser,
  IGovernmentUser,
  IGovernment,
  ICompany,
  IGroup,
  ICity,
} from "~/types/types";
import ToggleUpvote from "~/lib/mutations/ToggleUpvote";
import { useSelfie } from "~/components/auth/Auth";
import ToggleBookmark from "~/lib/mutations/ToggleBookmark";
import FeedCard from "~/components/feed/card/FeedCard";
import { Hover } from "react-powerplug";
import FeedCardActions from "~/components/feed/card/FeedCardActions";
import { FeedContext } from "~/components/feed/interfaces";
import { useToggle } from "react-use";
import { convertPostToInitialValues } from "~/components/post/utils";
import PostFormModal from "~/components/post/PostFormModal";
import PostDetails from "~/components/post/PostDetails";
import useIsMobile from "~/lib/hooks/useIsMobile";

type AuthorGovernment = Pick<IGovernment, "_id" | "slug" | "name" | "logo"> & {
  city: Pick<ICity, "countryName" | "country">;
};
type AuthorCompany = Pick<ICompany, "_id" | "slug" | "name" | "logo">;

type Author =
  | ({ __typename: "GovernmentUser" } & Pick<
      IGovernmentUser,
      "_id" | "slug" | "fullName" | "avatar" | "jobTitle" | "email"
    > & {
        government: AuthorGovernment | null;
      })
  | ({ __typename: "VendorUser" } & Pick<IVendorUser, "_id" | "slug" | "fullName" | "avatar" | "jobTitle" | "email"> & {
        company: AuthorCompany | null;
      })
  | ({ __typename: "PendingUser" } & Pick<IPendingUser, "_id" | "slug" | "fullName" | "avatar" | "jobTitle" | "email">);

interface IPostFeedItemProps {
  post: Pick<
    IPost,
    | "_id"
    | "body"
    | "viewerCanEdit"
    | "links"
    | "attachments"
    | "createdAt"
    | "upvotesCount"
    | "viewerDidUpvote"
    | "viewerDidBookmark"
    | "viewerDidComment"
    | "commentsCount"
    | "viewerCanEdit"
    | "audience"
  > & {
    author: Author;
  } & {
    government:
      | (Pick<IGovernment, "_id" | "name" | "slug" | "logo"> & {
          city: Pick<ICity, "countryName" | "country">;
        })
      | null;
  } & {
    group: Pick<IGroup, "_id" | "slug" | "name" | "logo"> | null;
  } & {
    vendor: Pick<ICompany, "_id" | "name" | "slug" | "logo"> | null;
  };
  createdAt: any;
  context?: FeedContext;
}

export default function PostFeedItem({ post, context }: IPostFeedItemProps): ReactElement {
  const viewer = useSelfie();
  const isMobile = useIsMobile();
  const [isOpen, toggleOpen] = useToggle(false);
  const postLink = {
    href: `/post?postId=${post._id}`,
    as: `/posts/${post._id}`,
  };

  return (
    <>
      <PostFormModal initialValues={convertPostToInitialValues(post)} isOpen={isOpen} onRequestClose={toggleOpen} />

      <Hover>
        {({ bind, hovered }) => (
          <FeedCard {...bind}>
            {viewer && (
              <ToggleUpvote
                viewer={viewer}
                upvotesCount={post.upvotesCount || 0}
                viewerDidUpvote={post.viewerDidUpvote || false}
                targetType="POST"
                targetId={post._id}
              >
                {({ toggle: toggleUpvote }) => (
                  <ToggleBookmark
                    targetId={post._id}
                    targetType="POST"
                    viewerDidBookmark={post.viewerDidBookmark || false}
                  >
                    {({ toggle: toggleBookmark }) => (
                      <FeedCardActions
                        isShowing={hovered || isMobile}
                        viewerDidUpvote={post.viewerDidUpvote || false}
                        onUpvote={toggleUpvote}
                        viewerDidBookmark={post.viewerDidBookmark || false}
                        onBookmark={toggleBookmark}
                        link={postLink}
                        viewerCanEdit={post.viewerCanEdit || false}
                        onEdit={toggleOpen}
                        viewerDidComment={post.viewerDidComment || false}
                      />
                    )}
                  </ToggleBookmark>
                )}
              </ToggleUpvote>
            )}

            <PostDetails post={post} context={context} />
          </FeedCard>
        )}
      </Hover>
    </>
  );
}
