import React, { SVGProps } from "react";

const CreateIcon = ({ stroke = "#666", ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} {...props}>
    <path
      d="M3.012 14.835l-.431-.028a1.264 1.264 0 01-1.168-1.184l-.013-.23a80.165 80.165 0 010-10.57l.013-.23c.05-.63.547-1.13 1.168-1.173l.225-.014a76.354 76.354 0 0110.401 0l.226.014c.62.05 1.111.555 1.154 1.187l.013.23c.233 3.519.233 7.05 0 10.57l-.013.23a1.266 1.266 0 01-1.168 1.175 76.9 76.9 0 01-10.407.023zM8 4.902v6.426M4.839 8.115h6.322"
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.933}
    />
  </svg>
);

export default CreateIcon;
