import { gql } from "@apollo/client";
import VendorPostFields from "~/lib/queries/fragments/VendorPostFields";

export default gql`
  mutation PostToVendor($vendor: ObjectId!, $post: PostInput!) {
    postToVendor(vendor: $vendor, post: $post) {
      ... on VendorPost {
        _id
        ...VendorPostFields
      }
    }
  }

  ${VendorPostFields}
`;
