import * as palette from "@govlaunch/palette";
import Link, { LinkProps } from "next/link";
import React from "react";
import { useSelfie } from "~/components/auth/Auth";
import BookmarkButtonIcon from "~/components/buttons/BookmarkButtonIcon";
import ThumbsUpButton from "~/components/buttons/ThumbsUpButton";
import CommentsIconV2 from "~/components/icons/CommentsIconV2";
import EditIcon from "~/components/icons/EditIcon";

interface IFeedCardActions {
  isShowing: boolean;
  onUpvote?: () => any;
  viewerDidUpvote?: boolean;
  onBookmark?: () => any;
  viewerDidBookmark?: boolean;
  link?: LinkProps;
  viewerCanEdit?: boolean;
  editLink?: LinkProps;
  onEdit?: () => any;
  viewerDidComment?: boolean;
}

const FeedCardActions: React.FunctionComponent<IFeedCardActions> = ({
  isShowing,
  onUpvote,
  viewerDidUpvote,
  onBookmark,
  viewerDidBookmark,
  link,
  viewerCanEdit,
  editLink,
  onEdit,
  viewerDidComment,
}) => {
  const viewer = useSelfie();

  if (!viewer) {
    return null;
  }

  return (
    <div
      css={{
        display: "grid",
        opacity: isShowing ? 1 : 0,
        position: "absolute",
        gridAutoFlow: "column",
        gridColumnGap: 10,
        top: 16,
        right: 16,
        transition: "opacity .12s",
      }}
    >
      {onUpvote && <ThumbsUpButton onClick={onUpvote} upvoted={viewerDidUpvote || false} />}

      {link && (
        <Link {...link} passHref={true}>
          <a>
            <CommentsIconV2
              fill={viewerDidComment ? palette.primary : palette.white}
              stroke={viewerDidComment ? palette.primary : palette.sealBlue}
            />
          </a>
        </Link>
      )}

      {onBookmark && (
        <BookmarkButtonIcon
          onClick={onBookmark}
          bookmarked={viewerDidBookmark || false}
          bookmarkedColor={palette.primary}
          bookmarkedStrokeColor={palette.primary}
        />
      )}

      {viewerCanEdit && editLink && (
        <Link {...editLink} passHref={true}>
          <a>
            <EditIcon color={palette.sealBlue} />
          </a>
        </Link>
      )}

      {viewerCanEdit && onEdit && (
        <button
          type="button"
          onClick={onEdit}
          css={{
            border: 0,
            padding: 0,
            background: "none",
            outline: "none",
            cursor: "pointer",
          }}
        >
          <EditIcon color={palette.sealBlue} />
        </button>
      )}
    </div>
  );
};

export default FeedCardActions;
