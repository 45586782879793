import React, { ReactNode } from "react";
import * as palette from "@govlaunch/palette";
import mediaQueries from "~/components/mediaQueries";

interface IProfileNavbarProps {
  children: ReactNode;
}

export default function ProfileNavbar({ children }: IProfileNavbarProps) {
  return (
    <div
      css={mediaQueries({
        borderRadius: 5,
        backgroundColor: palette.white,
        border: `solid 1px ${palette.lightestGray}`,
        padding: [8, 8, 0, 0],
      })}
    >
      <div
        css={mediaQueries({
          display: "flex",
          flexDirection: ["column", "column", "row", "row"],
          flexWrap: "wrap",
          textAlign: "center",
          marginRight: 0,
          alignItems: ["center", "center", "flex-start", "flex-start"],
          "& > :not(:last-of-type)": {
            marginBottom: [8, 8, 0, 0],
          },
        })}
      >
        {children}
      </div>
    </div>
  );
}
