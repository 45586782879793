import * as Types from "~/types/types";

type IUser =
  | ({ __typename: "PendingUser" } & Pick<Types.IPendingUser, "_id" | "email" | "isSiteAdmin" | "isLimitedSiteAdmin">)
  | ({ __typename: "VendorUser" } & Pick<Types.IVendorUser, "_id" | "email" | "isSiteAdmin" | "isLimitedSiteAdmin">)
  | ({ __typename: "GovernmentUser" } & Pick<
      Types.IGovernmentUser,
      "_id" | "email" | "isSiteAdmin" | "isLimitedSiteAdmin"
    > & {
        government: Types.Maybe<Pick<Types.IGovernment, "_id" | "slug">>;
      });

export default function isAdmin(user?: IUser | null): boolean {
  if (!user) {
    return false;
  }

  if (user.isSiteAdmin || user.isLimitedSiteAdmin) {
    return true;
  }

  if (user.__typename === "GovernmentUser" && user.government) {
    return user.government.slug === "govtopia";
  }

  return !user || (user.email || "").indexOf("@govlaunch.com") !== -1;
}
