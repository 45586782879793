import React from "react";
import { IConnectFieldsFragment } from "~/lib/queries/fragments/__generated__/ConnectFields.generated";
import { FeedContext } from "~/components/feed/interfaces";
import ProjectFeed from "~/components/feed/ProjectFeed";
import StoryFeed from "~/components/feed/StoryFeed";
import UserJoinedGovernmentFeed from "~/components/feed/UserJoinedGovernmentFeed";
import PostFeedItem from "~/components/feed/PostFeedItem";
import GovernmentJoinedGroupFeedItem from "~/components/feed/GovermentJoinedGroupFeedItem";
import VendorJoinedGroupFeedItem from "~/components/feed/VendorJoinedGroupFeedItem";
import UserJoinedGroupFeedItem from "~/components/feed/UserJoinedGroupFeedItem";

interface IFeedItem {
  feedItem: IConnectFieldsFragment;
  context?: FeedContext;
}

const FeedItem: React.FunctionComponent<IFeedItem> = ({ feedItem, context }) => {
  const { target, createdAt } = feedItem;

  if (!target) {
    return null;
  }

  if (
    target.__typename === "GroupProject" ||
    target.__typename === "GovernmentProject" ||
    target.__typename === "VendorProject"
  ) {
    return <ProjectFeed key={feedItem._id} project={target} createdAt={createdAt} context={context} />;
  }

  if (target.__typename === "Story") {
    return (
      <StoryFeed
        key={feedItem._id}
        story={{
          ...target,
          government: target.storyGovernment,
        }}
        createdAt={createdAt}
        context={context}
      />
    );
  }

  if (
    context === "group" &&
    (target.__typename === "GovernmentUser" ||
      target.__typename === "VendorUser" ||
      target.__typename === "PendingUser")
  ) {
    return <UserJoinedGroupFeedItem user={target} createdAt={createdAt} key={feedItem._id} />;
  }

  if (target.__typename === "Government") {
    return <GovernmentJoinedGroupFeedItem government={target} createdAt={createdAt} key={feedItem._id} />;
  }

  if (target.__typename === "Company") {
    return <VendorJoinedGroupFeedItem vendor={target} createdAt={createdAt} key={feedItem._id} />;
  }

  if (target.__typename === "GovernmentUser") {
    return <UserJoinedGovernmentFeed key={feedItem._id} user={target} createdAt={createdAt} />;
  }

  if (
    target.__typename === "GovernmentPost" ||
    target.__typename === "GroupPost" ||
    target.__typename === "VendorPost"
  ) {
    return (
      <PostFeedItem
        key={target._id}
        createdAt={createdAt}
        context={context}
        post={{
          _id: target._id,
          body: target.body,
          upvotesCount: target.upvotesCount,
          commentsCount: target.commentsCount,
          viewerDidBookmark: target.viewerDidBookmark,
          viewerDidUpvote: target.viewerDidUpvote,
          viewerDidComment: target.viewerDidComment,
          viewerCanEdit: target.viewerCanEdit,
          group: target.__typename === "GroupPost" ? target.postGroup : null,
          vendor: target.__typename === "VendorPost" ? target.postVendor : null,
          government: target.__typename === "GovernmentPost" ? target.postGovernment : null,
          createdAt: target.created,
          author: target.postAuthor,
          attachments: target.postAttachments,
          links: target.postLinks,
          audience: target.postAudience,
        }}
      />
    );
  }

  return null;
};

export default FeedItem;
