import React from "react";

const CommentsIconV2: React.FunctionComponent<React.SVGProps<any>> = ({
  fill = "#fff",
  stroke = "#A5ADBA",
  ...props
}) => (
  <svg viewBox="0 0 19 18" width={19} height={18} {...props}>
    <path
      d="M9.5 17.46H2.639v-2.94a.184.184 0 00-.045-.12C1.26 12.885.528 10.968.528 9 .528 4.335 4.553.54 9.5.54c4.947 0 8.972 3.795 8.972 8.46 0 4.665-4.025 8.46-8.972 8.46z"
      fillRule="nonzero"
      fill={fill}
      stroke={stroke}
    />
  </svg>
);

export default CommentsIconV2;
