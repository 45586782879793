import React, { SVGProps } from "react";

const UserProfileIcon = ({ stroke = "#666", ...props }: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.96}
      transform="translate(1 1)"
    >
      <path d="M2.012 13.835l-.431-.028a1.264 1.264 0 01-1.168-1.184l-.013-.23a80.165 80.165 0 010-10.57l.013-.23C.463.963.96.463 1.581.42l.225-.014a76.354 76.354 0 0110.401 0l.226.014c.62.05 1.111.555 1.154 1.187l.013.23c.233 3.519.233 7.05 0 10.57l-.013.23a1.266 1.266 0 01-1.168 1.175 76.9 76.9 0 01-10.407.023z" />
      <ellipse cx={7} cy={3.902} rx={2.032} ry={2.066} />
      <path d="M4.672 12.334h-.199a.535.535 0 01-.546-.434v-.083a23.12 23.12 0 010-3.874v-.082a.537.537 0 01.544-.436h.102a46.291 46.291 0 014.854 0h.102a.537.537 0 01.544.436v.082a23.12 23.12 0 010 3.874v.083a.535.535 0 01-.544.436 45.75 45.75 0 01-4.857-.002z" />
    </g>
  </svg>
);

export default UserProfileIcon;
